<nav class="bg-white sticky top-0 z-30 w-full mx-auto border-b-4 border-gray-200">
    <div class="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
            <div class="flex items-center">
                <div class="flex-shrink-0" routerLink="/">
                    <img class="h-8" src="assets/tribified-logo.svg" alt="Workflow logo" />
                </div>
                <div class="hidden md:block" *ngIf="enableMenus$ | async">
                    <!-- <div class="flex items-baseline"> -->
                        <div class="ml-10 flex items-baseline">
                        <!-- <a href="#" class="px-3 py-2 rounded-md text-sm font-medium text-white bg-indigo-800 focus:outline-none focus:text-white focus:bg-indigo-600">Dashboard</a> -->
                        <ng-container *ngFor="let item of mainNavItems">
                            <a (click)="navigateTo(item.route)" [routerLinkActive]="['bg-indigo-100', 'text-indigo-700']"
                                *ngIf="!item.gate || (item.gate == (customClaims$ | async).tier)"
                                class="ml-4 px-3 py-2 rounded-md text-sm font-medium text-indigo-600 hover:bg-indigo-100"><span>{{item.name}}</span></a>
                        </ng-container>

                    </div>
                </div>
            </div>
            <ng-container *ngIf="enableMenus$ | async">
                <div class="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
                    <div *appFeatureToggle="'search'" class="max-w-lg w-full lg:max-w-xs">
                        <label for="search" class="sr-only">Search</label>
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd"
                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                        clip-rule="evenodd" />
                                </svg>
                            </div>
                            <input id="search"
                                class="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:border-blue-300 focus:shadow-outline-blue sm:text-sm transition duration-150 ease-in-out"
                                placeholder="Search" type="search" />
                        </div>
                    </div>
                </div>
                <div class="hidden md:block">
                    <div class="ml-4 flex items-center md:ml-6">
                        <div *ngIf="spinner$ | async" class="p-1 focus:outline-none animate-spin text-teal-400"
                            aria-label="Refresh">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="3" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-refresh-cw">
                                <polyline points="23 4 23 10 17 10"></polyline>
                                <polyline points="1 20 1 14 7 14"></polyline>
                                <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                            </svg>
                        </div>

                        <span class="inline-block relative">
                            <button (click)="toggleNotificationsModal()"
                                class="p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition duration-150 ease-in-out"
                                aria-label="Notifications">
                                <svg class="h-8 w-8" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                </svg>
                            </button>
                            <span
                                class="absolute top-0 right-0 block h-5 w-5 rounded-full text-white text-xs shadow-solid bg-red-500 flex justify-center items-center"
                                *ngIf="notificationsCount | async as count">
                                <span class="m-1">{{ count > 9 ? '9+' : count }}</span>
                            </span>
                        </span>


                        <!-- Profile dropdown -->
                        <div *ngIf="user$ | async as user" class="ml-3 relative" (click)="toggleMenu()">
                            <div>
                                <div class="rounded-full"
                                    style="padding: 1px; background: linear-gradient(to right, #ed64a6, #805ad5)">
                                    <div class="bg-white rounded-full" style="padding: 1px;">
                                        <img class="h-8 w-8 rounded-full" [src]="user.igAccount.profileURL" alt="" />
                                    </div>
                                </div>
                                <!-- <button
                                    class="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid"
                                    id="user-menu" aria-label="User menu" aria-haspopup="true">
                                    <img class="h-8 w-8 rounded-full" [src]="igAccount.profileURL" alt="" />
                                </button> -->
                            </div>
                            <!-- Transparent background under the menu to prevent clicking on other things while menu is open -->
                            <div @dropdown *ngIf="menuOpen" class="fixed inset-0 transition-opacity z-100">
                                <div class="absolute inset-0 bg-transparent opacity-75"></div>
                            </div>
                            <div @dropdown *ngIf="menuOpen"
                                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-10">
                                <div class="py-1 rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical"
                                    aria-labelledby="user-menu">
                                    <a class="block px-4 font-bold py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        role="menuitem">{{user.igAccount.username}}</a>
                                    <ng-container *ngIf="syncInfo$ | async as syncInfo">
                                        <a *ngIf="syncInfo.type !== 'auto'" (click)="refresh()"
                                            class="block flex justify-between px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                            role="menuitem">
                                            Manual Sync
                                            <span class="text-gray-500" *ngIf="syncInfo.type === 'manual'">
                                                {{ syncInfo.datetime | shortDate }} ago</span>

                                        </a>
                                    </ng-container>
                                    <a routerLink="settings/account"
                                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        role="menuitem">Settings</a>
                                    <a href="https://tribified.com/contact/{{user.igAccount.username}}" target="_blank"
                                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        role="menuitem">Contact</a>
                                    <a (click)="signOut()"
                                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        role="menuitem">Sign out</a>
                                    <a (click)="toggleDemoModal()"
                                        class="inline-flex w-full space-x-2 block px-4 py-2 text-sm text-gray-700 border-t-2 border-gray-200 hover:bg-gray-100"
                                        role="menuitem">
                                        <svg class="flex-shrink-0 h-5 w-5 text-indigo-300"
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <span>
                                            Watch Demo
                                        </span>
                                    </a>

                                    <!-- <a (click)="toggleDemoModal()" class="-m-3 block inline-flex px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                                            <span class="ml-3">Watch Demo</span>
                                        </a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div (click)="toggleMobileMenu()" class="-mr-2 flex md:hidden">
                    <!-- Mobile menu button -->
                    <button
                        class="inline-flex items-center justify-center p-2 rounded-md text-indigo-600 hover:text-white hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600 focus:text-white">
                        <!-- Menu open: "hidden", Menu closed: "block" -->
                        <svg class="block h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                        <!-- Menu open: "block", Menu closed: "hidden" -->
                        <svg class="hidden h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>

    <!--
    Mobile menu, toggle classes based on menu state.

    Open: "block", closed: "hidden"
  -->
    <div @dropdown class="md:hidden" [ngClass]="{'block': mobileMenu, 'hidden': !mobileMenu}">
        <div class="px-2 pt-2 pb-3 sm:px-3" *ngIf="hasSubscription$ | async">
            <!-- <a href="#" class="block px-3 py-2 rounded-md text-base font-medium text-white bg-indigo-800 focus:outline-none focus:text-white focus:bg-gray-700">Dashboard</a> -->
            <div *ngFor="let item of mainNavItems">
                <a (click)="navigateTo(item.route)" *appFeatureToggle="item.route"
                    class="mt-1 block px-3 py-2 rounded-md text-sm font-medium text-indigo-600 hover:text-indigo-400 hover:bg-indigo-100">{{item.name}}</a>
            </div>
        </div>
        <div *ngIf="user$ | async as user" class="pt-4 pb-3 border-t border-indigo-800">
            <div class="flex items-center px-5">
                <div class="flex-shrink-0">
                    <img class="h-10 w-10 rounded-full" [src]="user.igAccount.profileURL" alt="" />
                </div>
                <div class="ml-3">
                    <!-- <div class="text-sm font-medium leading-none text-white">Tom Cook</div> -->
                    <div class="mt-1 text-sm font-medium leading-none text-gray-800">{{user.igAccount.username}}</div>
                </div>
            </div>
            <div class="mt-3 px-2">
                <ng-container *ngIf="syncInfo$ | async as syncInfo">
                    <a *ngIf="syncInfo.type !== 'auto'" (click)="refresh()"
                        class="mt-1 flex justify-between block px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:text-gray-600 hover:bg-gray-200 focus:outline-none focus:text-white focus:bg-gray-600">
                        Manual Sync
                        <span class="text-gray-500" *ngIf="syncInfo.type === 'manual'">
                            {{ syncInfo.datetime | shortDate }} ago</span>

                    </a>
                </ng-container>
                <a (click)="navigateTo('settings/account')"
                    class="mt-1 block px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:text-gray-600 hover:bg-gray-200 focus:outline-none focus:text-white focus:bg-gray-600">
                    Settings
                </a>
                
                <a href="https://tribified.com/contact/{{user.igAccount.username}}" target="_blank"
                    class="mt-1 block px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:text-gray-600 hover:bg-gray-200 focus:outline-none focus:text-white focus:bg-gray-600">
                    Contact
                </a>

                <a (click)="signOut()"
                    class="mt-1 block px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:text-gray-600 hover:bg-gray-200 focus:outline-none focus:text-white focus:bg-gray-600">
                    Sign out
                </a>
                
                <a (click)="toggleDemoModal()"
                    class="inline-flex w-full space-x-2  mt-1 block px-3 py-2 rounded-md text-sm font-medium text-gray-600 hover:text-gray-600 hover:bg-gray-200 focus:outline-none focus:text-white focus:bg-gray-600">
                    <svg class="flex-shrink-0 h-5 w-5 text-indigo-300"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>
                    Watch Demo
                </span>
                </a>

                <!-- <a (click)="toggleDemoModal()"
                class="inline-flex w-full space-x-2 block px-4 py-2 text-sm text-gray-700 border-t-2 border-gray-200 hover:bg-gray-100"
                role="menuitem">
                <svg class="flex-shrink-0 h-5 w-5 text-indigo-300"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>
                    Watch Demo
                </span>
            </a> -->
                
            </div>
        </div>
    </div>
</nav>
<div class="relative max-w-screen-xl sm:px-8 mx-auto flex flex-col">
    <!-- py-4 px-2 sm:py-6 sm:px-4 -->
    <main class="overflow-y-hidden">
        <!-- <div *ngIf="loading$ | async" class="absolute inset-0 z-20 spinner-1-lg" style="background-color: #F7FBFC;"></div> -->
        <ng-content></ng-content>
    </main>
</div>


<!-- NOTIFICATIONS OVERLAY -->
<!-- This example requires Tailwind CSS v2.0+ -->
<div *ngIf="showNotificationsModal" class="fixed inset-0 overflow-hidden">
    <div class="absolute inset-0 overflow-hidden">

        <div @notificationTrigger0 class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-50"></div>
        </div>

        <section @notificationTrigger1 class="absolute inset-y-0 right-0 pl-10 max-w-full flex"
            aria-labelledby="slide-over-heading">

            <div class="w-screen max-w-md">
                <div class="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                    <div class="mt-12 py-2 px-4 sm:px-6">
                        <div class="flex items-start justify-between">
                            <h2 id="slide-over-heading" class="text-lg font-medium text-gray-900">
                                Notifications
                            </h2>
                            <div class="ml-3 h-7 flex items-center">
                                <button (click)="toggleNotificationsModal()"
                                    class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    <span class="sr-only">Close panel</span>
                                    <!-- Heroicon name: x -->
                                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                        viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="mt-6 relative flex-1 px-4 sm:px-6">
                        <!-- Notifications View -->
                        <div class="absolute inset-0 px-4 sm:px-6 overflow-y-auto">
                            <!-- <div class="h-full border-2 border-dashed border-gray-200" aria-hidden="true"></div> -->
                            <app-notifications></app-notifications>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>


<!-- IN-APP DEMO VIDEO MODAL -->
<div *ngIf="showDemoModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
    aria-modal="true">
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div @trigger0 class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <div @trigger1
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
            <div class="relative bg-white pt-5">
                <div class="sm:flex sm:items-start">
                    <div class="mt-3 text-center sm:mt-0 sm:text-left">
                        <h3 class="text-lg leading-6 sm:ml-4 font-medium text-gray-900" id="modal-title">
                            Welcome <span *ngIf="user$ | async as user">{{user.firstName}}</span>
                        </h3>
                        <p class="sm:ml-4 text-sm text-gray-500">
                            To get started, checkout this video which will walk you through how to get the most out of
                            the app.
                        </p>
                        <!-- Modal Close -->
                        <svg (click)="toggleDemoModal()"
                            class="absolute h-6 top-0 m-2 right-0 text-gray-400 hover:text-gray-200 z-10"
                            fill="currentColor" viewBox="0 0 20 20">
                            <path fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"></path>
                        </svg>
                        <!-- Video -->
                        <div class="mt-2">
                            <div class="relative border-t-2 border-b-2 border-gray-200">
                                <video class="w-full h-full focus:outline-none" #video
                                    src="https://storage.googleapis.com/tribified-app-support-bucket/Tribified_InApp_Demo_Video_v2.mp4"
                                    controls>
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-gray-100 w-full px-4 py-3 sm:px-6 sm:inline-flex items-center space-x-4 ">
                <input id="comments" name="comments" type="checkbox" [(ngModel)]="alwaysShowDemoVideo"
                    class="focus:ring-indigo-800 h-4 w-4 text-indigo-900 border-gray-300 rounded">
                <span class="text-sm text-gray-500">
                    Show everytime I log in
                </span>
            </div>
        </div>
    </div>
</div>