import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationsService, Notification } from '../services/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit {

  notifications$: Observable<Map<string,Notification>>;

  constructor(
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.notifications$ = this.notificationsService.notifications$
  }

}
