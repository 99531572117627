import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LogService } from './log.service';
import { FirestoreService } from './firestore.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';
import { ApplicationEvent } from '../models/event-types';
import { ConfigurationService } from './configuration.service';

const MAILCHIMP_ENPOINT = 'https://tribified.us17.list-manage.com/subscribe/post-json?u=1578253f742575cae71a41c39&amp;id=3ef2974e8f';

export interface MailChimpResponse {
  result: string;
  msg: string;
}

export interface EmailSignUpDetails {
  fName: string;
  lName?: string;
  email: string;
  username?: string;
  source: "invite" | "lead-magnet" | "app-signup";
  followers?: number;
  updateEmail?: string
}

@Injectable({
  providedIn: 'root'
})
export class EmailSignupService {

  constructor(
    private http: HttpClient,
    private logger: LogService,
    private firestoreService: FirestoreService,
    private configurationService: ConfigurationService
  ) { }

  signupToEmailList(details: EmailSignUpDetails) {
    if (!environment.production){ return of({})}
    this.configurationService.logAnalyticsEvent(ApplicationEvent.EMAIL_SIGNUP, { username: details.username, email: details.email})
        if (details.source == 'invite') {
          this.firestoreService.requestInviteLink({
            ...details,
            token: null
          })
        }
        const params = new HttpParams()
          .set('EMAIL', details.email)
          .set('FNAME', details.fName)
          .set('LNAME', details.lName)
          .set('SOURCE', details.source)
          .set('b_1578253f742575cae71a41c39_3ef2974e8f', ''); // hidden input name

        const mailChimpUrl = `${MAILCHIMP_ENPOINT}&${params.toString()}`;

        // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
        return this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c')
  }

  categoriseAccSize(followers: number) {
    // Instagram account not found
    if (followers == -1) { return "N/A" }

    if (followers > 1e6) { return "2XL" }
    else if (followers > 5e5) { return "XL" }
    else if (followers > 1e5) { return "LG" }
    else if (followers > 5e4) { return "MD" }
    else if (followers > 1e4) { return "SM" }
    else { return "XS" }
  }

  categoriseSignUpFrom(from: "invite" | "lead-magnet" | "app-signup") {
    if (from == "invite") {
      return "group[43842][1]"
    } else if (from == "app-signup") {
      return "group[43842][2]"
    } else if (from == "lead-magnet") {
      return "group[43842][4]"
    } else {
      this.logger.error("Unknown Signup Source")
      return "group[43842][32]"
    }
  }
}
