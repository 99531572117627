import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { FirestoreService } from './firestore.service';
import { AuthService } from '../auth/services/auth.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';


export interface Notification {
  id: string,
  title: string,
  description: string,
  timestamp: string,
  htmlStr?: string,
  read: boolean,
  readTimestamp: string,
}


const AUTO_SYNC_SETUP: Notification = {
  id: "notification-auto-sync",
  title: "Auto Sync Setup Required",
  description: "To keep your Tribified data automatically in sync with Instagram, set up auto sync",
  htmlStr: `<a href='/settings/auto-sync' class="inline-flex items-center mt-4 mb-2 px-3 py-2 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Set up Auto Sync</a>`,
  timestamp: (new Date()).toISOString(),
  read: false,
  readTimestamp: null,
}


@Injectable({
  providedIn: 'root'
})
export class NotificationsService implements OnDestroy {

  currentNotifications: Map<string,Notification> = new Map<string, Notification>();

  // notifications$: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>(this.currentNotifications)
  notifications$: BehaviorSubject<Map<string,Notification>> = new BehaviorSubject<Map<string,Notification>>(this.currentNotifications)
  companyDetails: BehaviorSubject<any> = new BehaviorSubject<any>(undefined)
  notificationsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  subscriptions: Subscription[] = [];

  constructor(
    private firestoreService: FirestoreService,
    private authService: AuthService
  ) {
    // const igUid = this.authService.loggedInUser.value.currentIgUid
    this.subscriptions.push(
      this.authService.loggedInUser.pipe(filter( user => !!user))
      .pipe(switchMap(user => this.firestoreService.igUidSyncDetails(user.currentIgUid).pipe(filter( data => !!data)).pipe(map(info => info.type === 'auto'))))
      .subscribe( auto => {
        if (auto){
          this.removeNotification(AUTO_SYNC_SETUP)
        } else {
          this.addNotification(AUTO_SYNC_SETUP)
        }
      })
    )
  }

   addMultipleNotifications(notifications: Notification[]){
    notifications.forEach(n => {
      this.addNotification(n)
    })
   }

   addNotification(n: Notification){
     this.currentNotifications.set(n.id,n)
     this.updateNotifications()
   }

   removeNotification(n: Notification){
     this.currentNotifications.delete(n.id)
     this.updateNotifications()
    //  const removeAtIndex = this.currentNotifications.findIndex(notification => notification.id === n.id)

    //  if (removeAtIndex >=0){
    //   this.currentNotifications.splice(removeAtIndex,1)
    //  }
  }

   updateNotifications(){
     this.notifications$.next(this.currentNotifications)
     this.notificationsCount$.next(this.currentNotifications.size)
   }

   ngOnDestroy(){
     this.subscriptions.forEach(sub => sub.unsubscribe());
   }
}
