import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/services/auth.service';
import { DynamicCdnService } from './services/dynamic-cdn.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'tribified';
  env = environment.name

  constructor(){ }
}
