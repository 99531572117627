import { Component, Input, OnInit } from '@angular/core';


enum State {
  WAITING = "Waiting",
  INPROGRESS = "InProgress",
  PASS = "Pass",
  FAIL = "Fail",
}

@Component({
  selector: 'app-confirmation-tick-cross',
  templateUrl: './confirmation-tick-cross.component.html',
  styleUrls: ['./confirmation-tick-cross.component.scss']
})
export class ConfirmationTickCrossComponent implements OnInit {

  @Input("pass") pass: boolean;
  @Input("inProgress") inProgress: boolean;
  @Input("complete") complete: boolean;

  constructor() { }
  
  ngOnInit(): void {
  }

  getState(){
    if (this.complete){
      return this.pass ? State.PASS : State.FAIL
    } 
    else if (this.inProgress){
      return State.INPROGRESS
    } 
    else {
      return State.WAITING
      // return State.INPROGRESS
    }
  }

  

}
