import { EntityMetadataMap, EntityDataModuleConfig } from '@ngrx/data';
import { Relationship } from './models/relationship';

const entityMetadata: EntityMetadataMap = {
  Post: { },
  Relationship: {
    selectId: (rel: Relationship) => rel.username
  }
};

const pluralNames = { };

export const entityConfig: EntityDataModuleConfig = {
  entityMetadata,
  pluralNames
};
