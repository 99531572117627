<div class="min-h-screen flex flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8" 
[ngClass]="{'spinner-1-lg': loading}">
  <div class="max-w-xl w-full" [ngClass]="{'opacity-0': loading}">

    <div *ngIf="(inviteDetails || !inviteRequired); then inviteValid else invalidInvite">
        <!-- template will replace this div -->
      </div>

  </div>
</div>

<ng-template #inviteValid>
    <div>
        <img class="mx-auto h-12 w-auto" src="assets/tribified-logo.svg" alt="Workflow" />
        <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            {{ title }}
        </h2>
        <p class="mt-4 text-center text-sm leading-5 text-gray-600">
            {{ description }}
        </p>
    </div>

    <div class="mt-6 w-full flex flex-col items-center justify-center">

        <button type="submit" (click)="connectFacebook()"
            class="group flex justify-center items-center mt-4 py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-700 hover:bg-blue-600 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out">
            <span class="flex items-center pr-4">
                <svg class="h-6 w-6 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clip-rule="evenodd" />
                </svg>
            </span>
            Continue with Facebook
        </button>
    </div>
</ng-template>

<ng-template #invalidInvite>
    <div>
        <img class="mx-auto h-12 w-auto" src="assets/tribified-logo.svg" alt="Workflow" />
        <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
            Hey! Thanks for checking us out
        </h2>
        <p class="mt-4 text-center text-sm leading-5 text-gray-600">
            Unfortunately, we are not yet open for public release. However, we will be sending out invites for those registered to our Early Access email list.
            To join the list and become one of our Early Access users, please submit the form below.
        </p>

        <div class="mt-8">
            <app-email-signup></app-email-signup>
        </div>
    </div>
</ng-template>
<label id="__changeDetector__" hidden>{{ __changeDectector__() }}</label>