import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, Logger } from '@ngrx/data';
import { Update } from '@ngrx/entity';
import { from, Observable, of } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { Post } from 'src/app/models/post';
import { FirestoreService } from 'src/app/services/firestore.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Injectable({
  providedIn: "root"
})
export class PostDataService extends DefaultDataService<Post>{

  constructor(
    http: HttpClient, 
    httpUrlGenerator: HttpUrlGenerator, 
    logger: Logger, 
    private firestoreService: FirestoreService,
    private localStorage: LocalStorageService
    ) { 
    super('Post', http, httpUrlGenerator)
  }

  getAll(): Observable<Post[]> {
    const igUid = this.localStorage.storageUserData.currentIgUid;
    return this.firestoreService.getAllPosts(igUid)
  }

  update(post: Update<Post>): Observable<Post> {
    const igUid = this.localStorage.storageUserData.currentIgUid;
    const postId = post.id
    return from(this.firestoreService.updateMedia(igUid, post.id, post.changes))
    .pipe(switchMap(() => this.firestoreService.watchMediaChanges(igUid)))
    .pipe(map( posts => posts.find( p => p.id == postId)))
  }
}
