import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { EmailSignupService } from 'src/app/services/email-signup.service';



@Component({
  selector: 'app-email-signup',
  templateUrl: './email-signup.component.html',
  styleUrls: ['./email-signup.component.scss']
})
export class EmailSignupComponent implements OnInit, OnDestroy {

  form: FormGroup;
  error: string;
  submitted: boolean = false;

  @Input("confirmationMsg") confirmationMsg: string;

  currentStep: "email" | "details" = "email"
  subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder, private emailSignupService: EmailSignupService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      igUsername: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]]
    })

  }

  get name() {
    return this.form.get('name');
  }

  get igUsername() {
    return this.form.get('igUsername');
  }

  get email() {
    return this.form.get('email');
  }

  changeStep(step: "email" | "details") {
    this.currentStep = step;
  }

  addSubscriber() {
    this.error = '';
    if (this.form.valid) {

      this.subscriptions.push(this.emailSignupService.signupToEmailList({
        fName: this.name.value,
        username: this.igUsername.value,
        source: "invite",
        email: this.email.value,
        followers: 32423
      })
        .subscribe((response:any) => {
          if (response.result && response.result !== 'error') {
            this.submitted = true;
            this.form.reset();
          }
          else {
            if (response.msg.includes("already subscribed")) {
              this.error = "This email address has already subscribed"
            } else {
              // logger.error(error);
              // this.error = 'Sorry, an error occurred.';
              this.error = response.msg;
            }
            this.changeStep("email")
          }
        }, error => {

          if (error.msg.includes("already subscribed")) {
            this.error = "This email address has already subscribed"
          } else {
            // logger.error(error);
            // this.error = 'Sorry, an error occurred.';
            this.error = error.msg;
          }

        }));
    }
  }

  ngOnDestroy(){
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
