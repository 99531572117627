import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserStatus } from '../../models/user'; // optional

import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FirestoreService } from '../../services/firestore.service';
import { FunctionsService } from '../../services/functions.service';
import { LogService } from '../../services/log.service';
import { ConfigurationService } from '../../services/configuration.service';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { StripeService } from '../../services/stripe.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { RelationshipEntityService } from 'src/app/charts/services/relationship-entity.service';
import { PostEntityService } from 'src/app/feed/services/post-entity.service';

export interface AccountData {
  id: string;
  stats: any[]
}


@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

  permissions = [
    "email",
    "pages_show_list",
    "instagram_basic",
    "pages_manage_metadata",
    "instagram_manage_comments",
    "instagram_manage_insights",
    "pages_read_engagement",
    // "business_management" // used to check if a business is verified
  ]
  public user$: Observable<any> = this.afAuth.user.pipe(shareReplay(1));

  loggedInUser: BehaviorSubject<User> = new BehaviorSubject<User>(this.localStorage.storageUserData);
  fbAccessToken = new BehaviorSubject<string>(null);

  subscriptions: Subscription[] = [];

  appInitSetupRan = false;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private firestoreService: FirestoreService,
    private functionsService: FunctionsService,
    private logger: LogService,
    private stripeService: StripeService,
    private relationshipEntityService: RelationshipEntityService,
    private postEntityService: PostEntityService,
    private configurationService: ConfigurationService,
    private localStorage: LocalStorageService
  ) {
    // Get the auth state, then fetch the Firestore user document or return null
    this.afAuth.onAuthStateChanged(user => {
      // Logged in
      if (user) {
        // Update last login time
        this.configurationService.userUpdateDetails(user.uid, { 
          lastLogin: user.metadata.lastSignInTime,
          creationDate: user.metadata.creationTime
         })
         this.stripeService.fetchSubscriptionDetails(user)

         this.configurationService.syncAppMailchimpSubscription(user)
         
         this.subscriptions.push(this.configurationService.onUserDetailsChange(user.uid).subscribe(async (userDetails: any) => {
          if (userDetails) {
            // this.logger.info("userDetails", userDetails)
            // Authenticated and user exists in users collection
            this.fbAccessToken.next(userDetails.accessToken)
            delete userDetails.accessToken
            if (userDetails.currentIgUid
              && userDetails.canAccess.find(access => access.iguid === userDetails.currentIgUid).verified) {
              // Only retrieve linked account details once the access flag has been verified

              this.configurationService.getIgUidDetails(userDetails.currentIgUid)
                .subscribe(info => {

                  const updatedUser: User = {
                    ...userDetails,
                    igAccount: { ...info.details, setupComplete: info.setupStatus.complete }
                  }

                  this.updateLoggedInUser(updatedUser)

                  // TODO: Investigate if this can be done using APP_INITIALISE 
                  if(!this.appInitSetupRan){

                    this.getAllEntityData();

                    this.syncEntityChanges(userDetails.currentIgUid)

                    this.configurationService.refetchProfilePicture(userDetails.currentIgUid, this.fbAccessToken.value)

                    this.configurationService.syncPosts(userDetails.uid,userDetails.currentIgUid)

                    this.appInitSetupRan = true;
                  }
                })
              } else {
                this.updateLoggedInUser(userDetails)
            }
          } else {
            // Authenticated but not in users collection
            // Something has gone completely wrong and user needs to force resignup
            this.logger.fatal("AuthService: User signin but NOT in users collection")
            this.updateLoggedInUser(null)


            // This operation is sensitive and requires recent authentication. 
            // Log in again before retrying this request.
            // const token = await this.getAuthToken()
            // const credentials = firebase.auth.FacebookAuthProvider.credential(token)

            // this.afAuth.authState.pipe(switchMap(user => user.reauthenticateWithCredential(credentials)))
            // .pipe(switchMap(() => this.afAuth.authState))
            // .subscribe( user => user.delete() )


          }
        }));

        

      } else {
        // Logged out
        this.updateLoggedInUser(null)
        this.router.navigate(['/auth'])
      }
    }) 
  }

  async getAuthToken(){
    return await (await this.afAuth.currentUser).getIdToken(true)
  }
  
  updateLoggedInUser(user: User){
    if(user){
      this.localStorage.storageUserData = {
        uid: user.uid,
        currentIgUid: user.currentIgUid,
        igAccount: user.igAccount,
        email: user.email
      };
    } else {
      this.localStorage.storageUserData = null;
    }
    this.loggedInUser.next(user)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // Sign in with Facebook
  connectFacebook() {
    const fbAuthProvider = new firebase.auth.FacebookAuthProvider()
    // Add the required permissions
    this.permissions.forEach(permission => fbAuthProvider.addScope(permission))

    return this.afAuth.signInWithPopup(fbAuthProvider).then((credentials: any) => {
      // TODO: There is other info in this nested object that could be useful 
      const profileUrl = credentials.additionalUserInfo.profile.picture.data.url
      const firstName = credentials.additionalUserInfo.profile.first_name;
      const lastName = credentials.additionalUserInfo.profile.last_name;
      const user = credentials.user;

      // Register new user. merge is set to false so if user exists they won't
      // be reregistered.
      this.configurationService.userRegistration({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        firstName,
        lastName,
        userStatus: UserStatus.SIGNUP_INIT,
        photoURL: profileUrl,
        accessToken: credentials.credential.accessToken,
        canAccess: [],
        currentIgUid: '',
        showDemoVideo: true,
      }).subscribe( () => {
        this.fbAccessToken.next(credentials.credential.accessToken)
        return credentials;
      })
    });
  }

  resetUser(){
    // This deletes and signs out user
    this.afAuth.user.pipe(switchMap(user => this.functionsService.deleteUser(user.uid)))
    .subscribe(() => {
      this.signOut()
    }) 
  }

  getAllEntityData(){
    // Get the initial data and put into store. These check the entities loading$ flag first
    // so it will only run first time.
    this.relationshipEntityService.getAll()
    this.postEntityService.getAll()
  }

  syncEntityChanges(igUid){
    this.firestoreService.watchRelationshipChanges((igUid))
    .subscribe(changes => this.relationshipEntityService.upsertManyInCache(changes))

    this.firestoreService.watchPostChanges((igUid))
    .subscribe(changes => this.postEntityService.upsertManyInCache(changes))
  }

  async signOut() {
    await this.afAuth.signOut()
  }
}
