import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHandler, HttpHeaders, HttpParams} from '@angular/common/http';
import { startWith, map, shareReplay, tap, finalize, catchError, mergeMap, switchMap, filter } from 'rxjs/operators';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { AuthService } from '../auth/services/auth.service';
import { FirestoreService } from './firestore.service';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { ConfigurationService } from './configuration.service';
import { EmailSignUpDetails } from './email-signup.service';
import { User, UserStatus } from '../models/user';
import firebase from 'firebase/app';
import { ApplicationEvent } from '../models/event-types';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService implements OnDestroy {

  apiURL = environment.apiURL;

  refreshSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  subscriptions: Subscription[] = [];

  constructor( private http: HttpClient) { }

  requestData(userId, igAccountId, username, fbPageId, fbPageName, category) {
    const url = `${this.apiURL}/fetch-data?uid=${userId}&username=${username}&linked_account_id=${igAccountId}&fbPageId=${fbPageId}&fbPageName=${fbPageName}&category=${category}`;
    this.subscriptions.push(this.http.get(url).subscribe());
  }
  
  requestDataSubset(amount, userId, igAccountId, username) {
    this.refreshSpinner$.next(true)
    const url = `${this.apiURL}/fetch-data/subset?uid=${userId}&username=${username}&subset=${amount}&linked_account_id=${igAccountId}`;
    return this.http.get(url).pipe(finalize( () => this.refreshSpinner$.next(false)));
  }

  syncMedia(userId, igUid) {
    const url = `${this.apiURL}/fetch-data/sync-media?uid=${userId}&iguid=${igUid}`;
    return this.http.get(url);
  }

  fetchRecentPosts(amount, userId, igAccountId, username) {
    this.refreshSpinner$.next(true)
    const url = `${this.apiURL}/fetch-data/get-latest-posts?uid=${userId}&username=${username}&subset=${amount}&linked_account_id=${igAccountId}`;
    return this.http.get(url).pipe(finalize( () => this.refreshSpinner$.next(false)));
  }

  initPageSubscription(fbPageId: string, pageAccessToken: string) {
    const url = `${this.apiURL}/setup-webhooks?fbPageId=${fbPageId}&pageAccessToken=${pageAccessToken}`;

    this.subscriptions.push(this.http.get(url).subscribe());
  }

  updateEmailSubscriber(details: EmailSignUpDetails) {
    if( !environment.production ){ return of(details) }
    
    const size = this.categoriseAccSize(details.followers)
  
    const params = new HttpParams()
      .set('fname', details.fName)
      .set('lname', details.lName)
      .set('email', details.email)
      .set('igUsername', details.username)
      .set('size', size)
      .set('subscribeMethod', details.source)
      .set('appSubscription', UserStatus.SIGNUP_INIT)

      const url = `${this.apiURL}/mail/update-subscriber`;
      return this.http.post(url, params)
  }

  categoriseAccSize(followers: number) {
    // Instagram account not found
    if (followers == -1) { return "N/A" }

    if (followers > 1e6) { return "2XL" }
    else if (followers > 5e5) { return "XL" }
    else if (followers > 1e5) { return "LG" }
    else if (followers > 5e4) { return "MD" }
    else if (followers > 1e4) { return "SM" }
    else { return "XS" }
  }

  updateMailChimpEmail(current, preferred) {  
    if( current == null || preferred == null ){ return }

    const params = new HttpParams()
      .set('currentEmail', current)
      .set('newEmail', preferred)

      const url = `${this.apiURL}/mail/update-email`;
      return this.http.post(url, params)
  }

  updateMailChimpTag(email, from, to) {  
    const params = new HttpParams()
      .set('email', email)
      .set('removeTag', from)
      .set('addTag', to)

      const url = `${this.apiURL}/mail/update-tag`;
      return this.http.post(url, params)
  }

  updateMailChimpUserStatusTag(email, setTag) {
    const allStatuses = Object.values(UserStatus)
    const unsetTags: string[] = allStatuses.filter(status => status !== setTag)
    const params = new HttpParams()
      .set('email', email)
      .set('setTag', setTag)

      const url = `${this.apiURL}/mail/update-user-status-tag`;
      return this.http.post(url, params)
  }

  deleteUser(uid) {
    const url = `${environment.adminAPI}/delete-user`;
    const params = new HttpParams()
    .set('uid', uid)

    return this.http.post(url, params);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }
}
