<!-- <ng-container *ngFor="let notification of notifications$ | async">
    <div class="rounded-md bg-yellow-100 p-4 mb-4 border border-yellow-300">
        <div class="flex">
            <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fill-rule="evenodd"
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd" />
                </svg>
            </div>
            <div class="ml-3">
                <h3 class="text-sm leading-5 font-medium text-yellow-800">
                    {{notification.title}}
                </h3>
                <div class="mt-2 text-sm leading-5 text-yellow-700">
                        <p>
                            {{notification.description}}
                        </p>

                </div>
                <div *ngIf="notification.htmlStr" [innerHTML]="notification.htmlStr"></div>
            </div>
        </div>
    </div>
</ng-container> -->

<ng-container *ngFor="let notification of notifications$ | async | keyvalue">
    <div class="rounded-md bg-indigo-100 p-4 mb-4 border border-indigo-300">
        <div class="flex">
            <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor">
                    <path fill-rule="evenodd"
                        d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                        clip-rule="evenodd" />
                </svg>
            </div>
            <div class="ml-3">
                <h3 class="text-sm leading-5 font-medium text-indigo-800">
                    {{notification.value.title}}
                </h3>
                <div class="mt-2 text-sm leading-5 text-indigo-600">
                        <p>
                            {{notification.value.description}}
                        </p>

                </div>
                <div *ngIf="notification.value.htmlStr" [innerHTML]="notification.value.htmlStr"></div>
            </div>
        </div>
    </div>
</ng-container>


<!-- 
<div class="bg-white px-4 py-5  max-w-screen-xl mx-auto h-screen">
    <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
            Notifications
        </h3>
        <p class="mt-1 text-sm leading-5 text-gray-500">
            Manage your account settings and details here.
        </p>
    </div>

    <div class="pb-5 px-8">
        <h4 class="text-base leading-6 font-medium text-gray-700">
            Action Required
        </h4>
        <ng-container *ngIf="company$ | async as company">
            <div class="rounded-md bg-yellow-50 p-4" *ngIf="!company?.verified; else noActionsRequired">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm leading-5 font-medium text-yellow-800">
                            Verify Business
                        </h3>
                        <div class="mt-2 text-sm leading-5 text-yellow-700">
                            <ng-container *ngIf="company?.name; else noCompany">
                                <p>
                                    To keep your data automatically in sync with Instagram, verify the business connected to the
                                    {{ company.fbPageName }} Facebook page
                                    <br>
                                    <a href="https://www.facebook.com/business/help/2058515294227817" target="_blank"
                                        class="font-medium underline text-yellow-700 hover:text-yellow-600 transition ease-in-out duration-150">
                                        Verify {{ company.name }}
                                    </a>
                                </p>
                            </ng-container>
                            <ng-template #noCompany>
                                <p>
                                    To keep your data automatically in sync with Instagram:
                                </p>
                                    <ol class="mt-2 list-decimal list-inside">
                                        <li class="mb-1">
                                            <a href="https://www.facebook.com/business/help/720478807965744" target="_blank"
                                                class="font-medium underline text-yellow-700 hover:text-yellow-600 transition ease-in-out duration-150">
                                                Add the {{ company.fbPageName }}
                                            </a>
                                            Facebook page to your Facebook Business Manager account.</li>
                                        <li class="mb-1">
                                            <a href="https://www.facebook.com/business/help/2058515294227817" target="_blank"
                                                class="font-medium underline text-yellow-700 hover:text-yellow-600 transition ease-in-out duration-150">
                                                Verify the business
                                            </a>
                                            associated with your Facebook Business Manager account.
                                            </li>
                                    </ol>
                                <p>
                                    If you do not have a Business Manager account, 
                                    <a href="https://www.facebook.com/business/help/1710077379203657" target="_blank"
                                       class="font-medium underline text-yellow-700 hover:text-yellow-600 transition ease-in-out duration-150">
                                        click here to create one
                                    </a>
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

    </div>

    <div class="pb-5 px-8">
        <h4 class="text-base leading-6 font-medium text-gray-700">
            Recent
        </h4>
    </div>


</div>








<ng-template #noActionsRequired>
    <span>No Actions Required</span>
</ng-template> -->