import { Injectable, OnDestroy } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { FirestoreService } from './firestore.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import firebase from 'firebase/app';
import { tap } from 'rxjs/operators';

declare global {
  interface Window { Rewardful: any; }
}

@Injectable({
  providedIn: 'root'
})
export class StripeService implements OnDestroy {

  hasSubscription$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  activeSubscription$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  subscriptionPlans$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  customClaim$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  
  userId: string;

  subscriptions: Subscription[] = [];

  constructor(
    private firestoreService: FirestoreService
  ) { }

  fetchSubscriptionDetails(user: firebase.User){
    this.userId = user.uid;
    this.subscriptions.push(this.firestoreService.hasSubscription(this.userId).subscribe( res => this.hasSubscription$.next(res)))
    
    this.subscriptions.push(this.firestoreService.getSubscription(this.userId).subscribe( res => this.activeSubscription$.next(res)))
    
    this.subscriptions.push(this.firestoreService.getSubscriptionPlans().subscribe( res => this.subscriptionPlans$.next(res)))

    this.getCustomClaimRole(user).then( claims =>  {
      this.customClaim$.next({ tier: claims.stripeRole, onTrial: claims?.onTrial })
    })
  }

  async sendToCheckout(priceId: string){
    await this.firestoreService.initStripeCheckoutSession(priceId, this.userId, this.getClientReferenceId())
    .then((docRef) => {
      // Wait for the checkoutSession to get attached by the extension
      docRef.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          // Show an error to your customer and inspect
          // your Cloud Function logs in the Firebase console.
          alert(`An error occurred: ${error.message}`);
        }

        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          const stripe = await loadStripe(
            environment.STRIPE_PUBLISHABLE_KEY
          );
          await stripe.redirectToCheckout({ sessionId });
        }
      });
    })
  }

  getClientReferenceId() {
    return window.Rewardful && window.Rewardful.referral || ('checkout_'+(new Date).getTime());
  }

  async sendToCustomerPortal() {
    const functionRef = this.firestoreService.getHttpCallableFunction(environment.stripePortalCloudFunction);
    this.subscriptions.push(
      functionRef({ returnUrl: window.location.origin }).subscribe( data => {
        window.location.assign(data.url);
      })
    )

  }

  async getCustomClaimRole(user: firebase.User) {
    await user.getIdToken(true);
    const decodedToken = await user.getIdTokenResult()
    return decodedToken.claims
  }

  ngOnDestroy(){
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

}
