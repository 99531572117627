import { Pipe, PipeTransform } from '@angular/core';
import { LogService } from '../services/log.service';

@Pipe({
  name: 'shortDate'
})
export class ShortDatePipe implements PipeTransform {

  constructor(
    private logger: LogService
  ){}

  transform(timestamp: string, ...args: any[]): any {

    if (timestamp === null || timestamp === '' ){
      return null;
    }
    let period;
    let units;
    let delta

    try {
      delta = Date.now() - new Date(timestamp).getTime()
    } catch (error) {
      this.logger.error("invalid timestamp", timestamp)
      return null
    }

    if (this.inMinutes(delta) < 1){
      return "0m";
    } else if (this.inMinutes(delta) < 60) {
      period = this.inMinutes(delta);
      units = "m";
    } else if (this.inHours(delta) < 24) {
      period = this.inHours(delta);
      units = "h";
    } else if (this.inDays(delta) < 7) {
      period = (this.inDays(delta));
      units = "d";
    } else {
      period = (this.inDays(delta) / 7);
      units = "w";
    }

    return `${Math.trunc(period)}${units}`
  }

  inDays(msec: number) {
    return msec / 8.64e+7;
  }

  inHours(msec: number) {
    return msec / 3.6e+6;
  }

  inMinutes(msec: number) {
    return msec / 60000;
  }
}



// String postedAt(String posted) {
//   // Possibly change into switch statement
//   final postTime = posted.split('+')[0].replaceAll('T', ' ');
//   DateTime dtPostTime = DateTime.parse(postTime);
//   DateTime dtCurrent = DateTime.now();

//   Duration dtDiff = dtCurrent.difference(dtPostTime);
//   int period = 0;
//   //  if ((dtDiff.inDays ~/ 365) > 0) {
//   //    period = (dtDiff.inDays ~/ 365);
//   //    return "${period}y";
//   //  } else
//   if ((dtDiff.inDays ~/ 7) > 0) {
//   period = (dtDiff.inDays ~/ 7);
//   return "${period}w";
// } else if (dtDiff.inDays > 0) {
//   period = dtDiff.inDays;
//   return "${period}d";
// } else if (dtDiff.inHours > 0) {
//   period = dtDiff.inHours;
//   return "${period}h";
// } else if (dtDiff.inHours > 0) {
//   period = dtDiff.inHours;
//   return "${period}y";
// } else {
//   return "1m";
// }
// }
