export interface User {
  uid: string;
  email: string;
  preferredEmail?: string;
  photoURL?: string;
  displayName?: string;
  firstName?: string;
  lastName?: string;
  igAccount?: any;
  fbPageId?: string;
  currentIgUid?: string;
  canAccess?: any[];
  userStatus?: string;
  // pageAccessToken?: string;
  setupComplete?: boolean;
  showDemoVideo?: boolean;
}

export interface AccountProfile {
  username: string;
  profileURL: string;
  selected: boolean;
  pageAccessToken?: string;
  // accessToken?: string;
  followersCount?: number;
  followsCount?: number;
  id?: string;
  fbPageId?: string;
  mediaCount?: number;
  category?: string;
}

export enum UserStatus {
  SIGNUP_INIT = 'signup-init',
  SIGNEDUP_NOT_ACTIVE = 'not-active',
  SUBSCRIBED = 'subscribed', // from firebase extention, same as active
  TRIAL_END = 'trial_ended',
  // From Stripe's Subscription Status
  TRIALING = 'trialing',
  INCOMPLETE= 'incomplete',
  INCOMPLETE_EXPIRED= 'incomplete_expired',
  ACTIVE= 'active', // from stripe, same as subscribed
  PAST_DUE= 'past_due',
  CANCELED= 'canceled',
  UNPAID= 'unpaid'
}