import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-confirmation-tick',
  templateUrl: './confirmation-tick.component.html',
  styleUrls: ['./confirmation-tick.component.scss']
})
export class ConfirmationTickComponent implements OnInit {

  @Input("confirmed") confirmed: boolean;

  constructor() { }
  
  ngOnInit(): void {
  }

}
