import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loading$: Subject<boolean> = new Subject<boolean>()

  constructor() { 
    this.loading$.next(true)
  }

  setLoading(status: boolean){
    this.loading$.next(status)
  }
}
