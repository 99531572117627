import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'absoluteNumber'
})
export class AbsoluteNumberPipe implements PipeTransform {

  transform(num: number, ...args: any[]): any {
    if (num === null || isNaN(num) ){
      return null;
    }

    return Math.abs(num)
  }

}
