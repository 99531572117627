import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[appFeatureToggle]'
})
export class FeatureToggleDirective {

  @Input() appFeatureToggle: string;
  features;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnInit() {
    this.features = environment.features;

    if (this.isEnabled()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  isEnabled() {
    if (this.features['*'] || this.features[this.appFeatureToggle]=== undefined) {
      return true;
    }

    return this.features[this.appFeatureToggle];
  }

}
