import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GraphApiService implements OnDestroy {

  grapApiUrl = 'https://graph.facebook.com';

  subscriptions: Subscription[] = []

  constructor(
    // private authService: AuthService,
    private http: HttpClient) { }

  getInstagramBusinessAccountIDs(fbAccessToken) {
    const url = `${this.grapApiUrl}/me?fields=accounts{username,access_token,instagram_business_account,category,name}&access_token=${fbAccessToken}`;

    return this.http.get(url)
      .pipe(
        map((response: any) => {
          const _accounts = [];
          response.accounts.data.forEach(element => {
            if (element.instagram_business_account) { _accounts.push({ id: element.instagram_business_account.id, category: element.category, pageId: element.id, fbPageName: element.name, pageAccessToken: element.access_token }); }
          });
          return _accounts;
        }));
  }

  getInstagramBusinessAccount(igBusinessId, accessToken, pageId, fbPageName, category?) {
    const url = `${this.grapApiUrl}/${igBusinessId}?fields=username,followers_count,follows_count,media_count,profile_picture_url&access_token=${accessToken}`;

    return this.http.get(url)
      .pipe(map((data: any) => {
        return { id: data.id, fbPageId: pageId, fbPageName, category, pageAccessToken: accessToken, username: data.username, followersCount: data.followers_count, followsCount: data.follows_count, mediaCount: data.media_count, profileURL: data.profile_picture_url }
      }));
  }

  // This only works for usernames who are business/creator accounts
  // otherwise it returns an error. Reluctant to use this at the moment
  // because if we keep making requests to the facebook api which return
  // an error, this could lead to issues. TODO: need to find a way to determine
  // if a username is a business/creator account first
  getIGBusinessDescoveryDetails(igBusinessId, username, accessToken) {
    const url = `${this.grapApiUrl}/${igBusinessId}?fields=business_discovery.username(${username}){profile_picture_url,biography,follows_count,followers_count,media_count,name,website}&access_token=${accessToken}`;

    return this.http.get(url)
    .pipe(map( (response: any) => response.business_discovery))
    .pipe(catchError(error => of(null))) // triggered when not a business account
  }

  postComment(mediaId: string, comment: string, accessToken: string) {
    const url = `${this.grapApiUrl}/${mediaId}/comments`;
    return this.http.post(url, { message: comment, access_token: accessToken });
  }

  getMediaSrc(mediaId, accessToken: string){
    // const promise = fetch(`${kBaseURL}/${mediaId}?fields={id,like_count,media,text,timestamp,username,replies.limit(50){id,like_count,media,text,timestamp,username}},caption,id,like_count,media_type,media_url,thumbnail_url,timestamp,username,children{media_url,timestamp,id}&limit=400&access_token=${accessToken}`);
    const url = `${this.grapApiUrl}/${mediaId}?fields=id,media_type,media_url,thumbnail_url,timestamp,username,children{media_url,timestamp,id}&access_token=${accessToken}`;
    return this.http.get(url);
  }

  getComment(commentId: string, accessToken: string) {
    const url = `${this.grapApiUrl}/${commentId}?fields=id,timestamp,username,text&access_token=${accessToken}`;
    return this.http.get(url);
  }

  postReplies(commentId: string, reply: string, accessToken: string) {
    const url = `${this.grapApiUrl}/${commentId}/replies`;
    this.subscriptions.push(this.http.post(url, { message: reply, access_token: accessToken }).subscribe());
  }

  getIgAccountInsights(igAccountId, accessToken: string) {
    const THIRTY_DAYS_SEC = 2592000; //30 days x 24 hrs x 3600 sec/hr

    const to = new Date();

    const until = Math.trunc(to.getTime() / 1000) // now
    const since = until - THIRTY_DAYS_SEC


    const url = `${this.grapApiUrl}/${igAccountId}/insights?metric=impressions,reach,profile_views,email_contacts,phone_call_clicks,website_clicks,follower_count&period=day&since=${since}&until=${until}&access_token=${accessToken}`;

    return this.http.get(url);
  }

  getPreviousInsights(url) {
    // follower_count is only available for the latest 30 days, so remove from query else this
    // will error out
    const cleanedUrl = url.replace('%2Cfollower_count','')
    return this.http.get(cleanedUrl);
  }

  getPostInsights(mediaId, accessToken: string) {
    const url = `${this.grapApiUrl}/${mediaId}/insights?metric=engagement,impressions,reach,saved&access_token=${accessToken}`;

    return this.http.get(url);
  }

  getVideoInsights(mediaId, accessToken: string) {
    const url = `${this.grapApiUrl}/${mediaId}/insights?metric=engagement,impressions,reach,saved,video_views&access_token=${accessToken}`;

    return this.http.get(url);
  }

  getCarouselInsights(mediaId, accessToken: string) {
    const url = `${this.grapApiUrl}/${mediaId}/insights?metric=carousel_album_engagement,carousel_album_impressions,carousel_album_reach,carousel_album_saved,carousel_album_video_views&access_token=${accessToken}`;

    return this.http.get(url);
  }

  // setup page subscriptions. This is a required step for setting up webhooks
  initPageSubscription(fbPageId: string, pageAccessToken: string) {
    // the subscibed field (feeds) is arbituary, we actually want to subscribe to the comments field which is 
    // setup on the developer webhooks dashboard 
    // const url = `${this.grapApiUrl}/v8.0/${fbPageId}/subscribed_apps?subscribed_fields=feed&access_token=${pageAccessToken}`;
    const url = `${this.grapApiUrl}/v8.0/${fbPageId}/subscribed_apps`;

    return this.http.post(url, { subscribed_fields: "email", access_token: pageAccessToken });
  }

  setCommentHide(commentId: string, state: boolean, accessToken: string){
    const url = `${this.grapApiUrl}/${commentId}?hide=${state}`;
    return this.http.post(url, { hide: true, access_token: accessToken })
  }

  deleteComment(commentId: string, accessToken: string){
    const url = `${this.grapApiUrl}/${commentId}?access_token=${accessToken}`;
    return this.http.delete(url);
  }

  getIgUidProfilePicture(igUid,  accessToken: string) {
    const url = `${this.grapApiUrl}/${igUid}?fields=profile_picture_url&access_token=${accessToken}`;

    return this.http.get(url)
      .pipe(map((data: any) => data.profile_picture_url));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
  }
}