import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { createLogger, DEBUG } from 'browser-bunyan';
import { ConsoleFormattedStream } from '@browser-bunyan/console-formatted-stream';
import { HttpClient } from '@angular/common/http';
import { take, switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

export const enum LogLevel {
  TRACE = 10,
  DEBUG = 20,
  INFO = 30,
  WARN = 40,
  ERROR = 50,
  FATAL = 60
}

interface LogEntry {
  // Public Properties
  uuid?: string;
  // entryDate: Date;
  message: string;
  level: LogLevel;
  details?: any;
  // logWithDate: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LogService {

  serverMinLogLevel = LogLevel.WARN

  logUrl = `${environment.logServerURL}/log`

  private logger;

  constructor(
    private http: HttpClient,
    private aFA: AngularFireAuth
  ) {

    // Create a Bunyan logger that streams to console
    this.logger = createLogger({
      name: `Tribified-${environment.name}`,
      streams: [
        {
          level: DEBUG, // or use the string 'info'
          stream: new ConsoleFormattedStream()
        },
      ],
    });
  }
  // CHECKME
  logToServer(level: LogLevel, msg: string, details?: any) {
    if (environment.production && (level >= this.serverMinLogLevel)) {
      this.aFA.user.pipe(take(1)).pipe(switchMap(user => {
        const entry: LogEntry = {
          uuid: user?.uid || null,
          level: level,
          message: msg,
          details: details || null
        }
        return this.http.post(this.logUrl, { logEntry: entry }).pipe(take(1))
      })).subscribe()
    }
  }

  trace(msg: string, details?: any) {
    this.logger.trace(msg)
    this.logToServer(LogLevel.TRACE, msg, details || null)
  }

  debug(msg: string, details?: any) {
    this.logger.debug(msg, details || '')
    this.logToServer(LogLevel.DEBUG, msg, details || null)
  }

  info(msg: string, details?: any) {
    this.logger.info(msg, details || '')
    this.logToServer(LogLevel.INFO, msg, details || null)
  }

  warn(msg: string, details?: any) {
    this.logger.warn(msg, details || '')
    this.logToServer(LogLevel.WARN, msg, details || null)
  }

  error(msg: string, details?: any) {
    this.logger.error(msg, details || '')
    this.logToServer(LogLevel.ERROR, msg, details || null)
  }

  fatal(msg: string, details?: any) {
    this.logger.fatal(msg, details || '')
    this.logToServer(LogLevel.FATAL, msg, details || null)
  }
}
