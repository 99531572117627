import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogService } from './log.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpIntercept implements HttpInterceptor {
    constructor(
        private logger: LogService
    ){}

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) =>{
            if (error.error instanceof ErrorEvent) {
              // A client-side or network error occurred. Handle it accordingly.
            //   logger.error('An error occurred:', error.error.message);
              this.logger.error("A client-side or network error occurred.", error.error)
            } else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              this.logger.error(`Backend returned an unsuccessful response code ${error.status}`, error.error);
            }
            // return an observable with a user-facing error message
            return throwError(error);
          })
    );
  }
}


