export const environment = {
  name: 'Production',
  production: true,
  inviteRequired: false,
  apiURL: 'https://us-central1-tribified-prod.cloudfunctions.net/api',
  adminAPI: 'https://us-central1-tribified-prod.cloudfunctions.net/adminapi/v1',
  logServerURL: 'https://log-server-5inzji4ckq-uc.a.run.app',
  firebaseConfig: {
    apiKey: "AIzaSyClqVS4AauAaLZtTxBb0lidSebTB54yxag",
    authDomain: "tribified-prod.firebaseapp.com",
    databaseURL: "https://tribified-prod.firebaseio.com",
    projectId: "tribified-prod",
    storageBucket: "tribified-prod.appspot.com",
    messagingSenderId: "213888662982",
    appId: "1:213888662982:web:fd4a1d365fc7f1db4d46c7",
    measurementId: "G-VHZZXCG0ZW"
  },
  STRIPE_PUBLISHABLE_KEY: "pk_live_51IFzqdHqakBLKNDDt5aPKKnkVzHuOIbkIHWwpAHWTxGmHg3qiZWvg5wLDNQNgpNJ0FxChXqgb39gjPdpNCf4d7yn00QwoUIzxy",
  stripePortalCloudFunction: "ext-firestore-stripe-subscriptions-createPortalLink",
  features: {
    login: true,
    charts: false,
    search: false,
    manifesto: false,
    manualRefresh: true
  }
};
