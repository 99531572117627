import { Injectable } from '@angular/core';
import { FirestoreService } from './firestore.service';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { LogService } from './log.service';
import { User } from '../models/user';
import { noop, of, Subscription } from 'rxjs';
import firebase from 'firebase/app';
import { FunctionsService } from './functions.service';
import { ApplicationEvent } from '../models/event-types';
import { environment } from 'src/environments/environment';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { PostEntityService } from '../feed/services/post-entity.service';
import { RelationshipEntityService } from '../charts/services/relationship-entity.service';
import { GraphApiService } from './graph-api.service';
import { LocalStorageService } from './local-storage.service';



@Injectable({
  providedIn: "root"
})
export class ConfigurationService {
  subscriptions: Subscription[] = [];

  constructor(
    private firestoreService: FirestoreService,
    private functionsService: FunctionsService,
    private graphApiService: GraphApiService,
    private analytics: AngularFireAnalytics,
    private logger: LogService,
    private localStorage: LocalStorageService
  ) { }

  syncAppMailchimpSubscription(user: firebase.User){
    const storedUser: User = this.localStorage.storageUserData

    this.subscriptions.push(
    this.firestoreService.watchSubscription(user.uid)
    .pipe(filter( (val: any[]) => val.length != 0),
          map( (arr:any[]) => arr[0].status))
     .pipe(mergeMap((toStatus) => of({ from: storedUser?.userStatus, to: toStatus})))
     .pipe(filter(s => !!s.from && !!s.to && s.from !== s.to))
     .pipe(switchMap((status) => storedUser.preferredEmail ? this.setUserStatus(status.to, storedUser.preferredEmail) : of()))
    .subscribe()
    )
  }

  setPreferredEmail(current, preferred){
    const uid = this.localStorage.storageUserData.uid;
    this.logAnalyticsEvent(ApplicationEvent.EMAIL_UPDATE, { from: current, to: preferred})

    const updateMailchimp$ = this.functionsService.updateMailChimpEmail(current, preferred);
    const updateFirestore$ = this.firestoreService.updateUserDetails(uid, { preferredEmail: preferred });

    return (environment.production && !!current) ? updateMailchimp$.pipe(switchMap(() => updateFirestore$)) : updateFirestore$;
  }

  setUserStatus(to, email){
    const user = this.localStorage.storageUserData;
    this.logAnalyticsEvent(ApplicationEvent.USER_STATUS_UPDATE, { to})

    const updateMailchimp$ = this.functionsService.updateMailChimpUserStatusTag(email, to);
    const updateFirestore$ = this.firestoreService.updateUserDetails(user.uid, { userStatus: to });

    return environment.production ? updateMailchimp$.pipe(switchMap(() => updateFirestore$)) : updateFirestore$;
  }

  userRegistration(user){
    return this.firestoreService.registerUser(user);
  }

  userUpdateDetails(igUid, update){
    return this.firestoreService.updateUserDetails(igUid, update)
  }

  getIgUidDetails(igUid){
    return this.firestoreService.getLinkedIgDetails(igUid)
  }

  onUserDetailsChange(userId){
    return this.firestoreService.onUserDetailsChange(userId);
  }

  refetchProfilePicture(igUid, accessToken){
    this.graphApiService.getIgUidProfilePicture(igUid, accessToken)
    .pipe(tap(console.log))
    .pipe(switchMap((profileURL: string) => {
      const partialAccDeets = {
        profileURL
      }
      return this.firestoreService.setIgAccountDetails(igUid, { accountDetails: partialAccDeets})
    }))
    .subscribe()
  }

  syncPosts(uid, igUid){
    this.functionsService.syncMedia(uid, igUid)
    .subscribe(noop) 
  }


  logAnalyticsEvent(eventName: ApplicationEvent, object?: any){
    const uid = this.localStorage.storageUserData.uid;
    const igUid = this.localStorage.storageUserData.currentIgUid;
    if (uid && igUid){
      object ? this.analytics.logEvent(eventName, { uid, igUid, ...object }) : this.analytics.logEvent(eventName, { uid, igUid });
      this.firestoreService.logUserAnalytics(uid, igUid, eventName)
    } else {
      object ? this.analytics.logEvent(eventName, {...object}) : this.analytics.logEvent(eventName);
    }
  }

  markTokenAsUsed(updatedInvite) {
    this.firestoreService.updateInviteToken(updatedInvite);
  }

  validateInviteToken(token) {
    return this.firestoreService.validateInviteToken(token)
      .pipe(map((doc => {
        if (!doc.exists) { return undefined; }
        const data: any = doc.data()
        const invite: any = { ...data, id: doc.id }
        invite.redeemed ?
          this.logger.warn("Invite token is already used") :
          this.logger.info("Valid unused invite token")
        return invite
      })))
  }
}
