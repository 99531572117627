import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../services/configuration.service';

export interface Invite {
    id: string;
    usedBy: Array<string>;
    redeemed: boolean;
    type: "single" | "multi";
    assignedTo: string;
}

@Injectable({ providedIn: 'root' })
export class InviteTokenResolver implements Resolve<Invite> {

    constructor(private configurationService: ConfigurationService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const inviteToken = route.paramMap.get('inviteToken')
        console.log({inviteToken})
        return this.configurationService.validateInviteToken(inviteToken);
    }

}