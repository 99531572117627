import { NgModule } from '@angular/core';
import { ShortDatePipe } from '../pipes/short-date.pipe';
import { ShortNumberPipe } from '../pipes/short-number.pipe';
import { FeatureToggleDirective } from './directives/feature-toggle.directive';
import { AbsoluteNumberPipe } from '../pipes/absolute-number.pipe';
import { EmailSignupComponent } from './components/email-signup/email-signup.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ConfirmationTickComponent } from './components/confirmation-tick/confirmation-tick.component';
import { HttpClientJsonpModule } from '@angular/common/http';
import { AbreviatePipe } from '../pipes/abreviate.pipe';
import { ConfirmationTickCrossComponent } from './components/confirmation-tick-cross/confirmation-tick-cross.component';
import { TrialLengthRemainingPipe } from '../pipes/trial-length-remaining.pipe';
import { ImageLoaderDirective } from './directives/image-loader.directive';


const components = [
  ShortDatePipe, 
  ShortNumberPipe, 
  AbsoluteNumberPipe, 
  FeatureToggleDirective, 
  EmailSignupComponent,
  ConfirmationTickComponent,
  AbreviatePipe,
  ConfirmationTickCrossComponent,
  TrialLengthRemainingPipe,
  ImageLoaderDirective
];

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientJsonpModule,
  // AngularFireModule.initializeApp(environment.firebaseConfig)
];

@NgModule({

  declarations: [...components],
  imports: [...modules
  ],
  exports: [
    ...components,
    ...modules
  ]
})
export class SharedModule { }
