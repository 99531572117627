import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, Logger } from '@ngrx/data';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from 'src/app/models/user';
import { Relationship } from 'src/app/models/relationship';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class RelationshipDataService extends DefaultDataService<Relationship>{

  constructor(
    http: HttpClient, 
    httpUrlGenerator: HttpUrlGenerator, 
    logger: Logger, 
    private firestoreService: FirestoreService,
    private configurationService: ConfigurationService,
    private localStorage: LocalStorageService
    ) { 
    super('Relationship', http, httpUrlGenerator)
  }

  getAll(): Observable<Relationship[]> {
    const user: Partial<User> = this.localStorage.storageUserData;
    return this.firestoreService.getAllRelationships(user.currentIgUid) as Observable<Relationship[]>
  }

  getWithQuery(username: string): Observable<Relationship[]>{
    const igUid = this.localStorage.storageUserData.currentIgUid;
    return this.firestoreService.getRelationships(igUid, username)
  }
}
