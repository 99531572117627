<!-- <form [formGroup]="form" (ngSubmit)="addSubscriber()" class="flex flex-col">
    <div class="w-full flex flex-row">
        <input formControlName="name" aria-label="First name" type="text"
            class="w-1/2 px-3 py-2 m-2 border border-gray-300 text-base  rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out"
            placeholder="First name">
        <input formControlName="igUsername" aria-label="Instagram username" type="text"
            class="w-1/2 px-3 py-2 m-2 border border-gray-300 text-base  rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out"
            placeholder="Instagram username">
    </div>
    <div class="w-full flex flex-row">
        <input formControlName="email" aria-label="Email address" type="email"
            class="w-full px-3 py-2 m-2 border border-gray-300 text-base  rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out"
            placeholder="Email">
        <button type="submit" [disabled]="!form.valid" class="text-white m-2 px-3 py-2 rounded-md"
            [ngClass]="{'bg-gray-400': form.invalid, 'bg-teal-400': form.valid}">Submit</button>
    </div>
</form> -->
<div id="signup" class="relative mt-3 w-full">
<form *ngIf="!submitted" [formGroup]="form" (ngSubmit)="addSubscriber()" action="#" method="POST" class="sm:flex">
    <input *ngIf="currentStep == 'email'" aria-label="Email" type="email" formControlName="email"
      class="appearance-none block w-full px-3 py-3 border border-gray-300 text-base leading-6 rounded-md placeholder-gray-500 shadow-sm focus:outline-none focus:placeholder-gray-400 focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:flex-1"
      placeholder="Enter your email" />
    <div *ngIf="currentStep == 'details'" class="w-full flex flex-row">
        <input formControlName="name" aria-label="First name" type="text"
            class="appearance-none block w-full mr-1 px-3 py-3 border border-gray-300 text-base leading-6 rounded-md placeholder-gray-500 shadow-sm focus:outline-none focus:placeholder-gray-400 focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:flex-1"
            placeholder="First name">
        <input formControlName="igUsername" aria-label="Instagram username" type="text"
            class="appearance-none block w-full ml-1 px-3 py-3 border border-gray-300 text-base leading-6 rounded-md placeholder-gray-500 shadow-sm focus:outline-none focus:placeholder-gray-400 focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:flex-1"
            placeholder="Instagram username">
    </div>
    <button *ngIf="currentStep == 'email'" type="button" [disabled]="email.invalid"
        (click)="changeStep('details')"
        [ngClass]="{'bg-gray-400': email.invalid && email.dirty, 'bg-gray-800': email.valid || email.value == ''}"
      class="mt-3 w-full px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray active:bg-gray-900 transition duration-150 ease-in-out sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto">
      Notify me
    </button>
    <button *ngIf="currentStep == 'details'" type="submit" [disabled]="!form.valid"
    [ngClass]="{'bg-gray-400': form.invalid, 'bg-teal-400': form.valid}"
      class="mt-3 w-full px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white shadow-sm focus:outline-none focus:border-teal-900 focus:shadow-outline-teal active:bg-teal-900 transition duration-150 ease-in-out sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto">
      Submit
    </button>
  </form> 
  <p class="mt-2 text-red-500 text-sm">{{ error }}</p>


  <div class="absolute w-full h-full flex flex-col justify-center items-center">
    <div class="w-12 h-12">
      <app-confirmation-tick [confirmed]="submitted"></app-confirmation-tick>
    </div>
    <p *ngIf="submitted && confirmationMsg" class="mt-2 text-gray-700">{{ confirmationMsg }}</p>
  </div>
</div>
