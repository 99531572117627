import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { AngularFireModule } from '@angular/fire';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { FooterComponent } from './footer/footer.component';
import { HttpIntercept } from './services/http-intercept';
import { ShellComponent } from './shell/shell.component';
import { AuthComponent } from './auth/auth.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EntityDataModule, EntityDataService, EntityDefinitionService } from '@ngrx/data';
import { entityConfig } from './entity-metadata';
import { EffectsModule } from '@ngrx/effects';
import { RelationshipEntityService } from './charts/services/relationship-entity.service';
import { RelationshipDataService } from './charts/services/relationship-data.service';
import { PostEntityService } from './feed/services/post-entity.service';
import { PostDataService } from './feed/services/post-data.service';
import { ConfigurationService } from './services/configuration.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    FooterComponent,
    ShellComponent,
    AuthComponent,
    NotificationsComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    StoreModule.forRoot({}, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionSerializability: true,
        strictStateSerializability: true
      }
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot({}),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpIntercept, multi: true },
    ScreenTrackingService,
    UserTrackingService,
    ShortNumberPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(
    private eds: EntityDefinitionService,
    private entityDataService: EntityDataService,
    private relationshipsDataService: RelationshipDataService,
    private postsDataService: PostDataService,
    ){
    this.eds.registerMetadataMap(entityConfig.entityMetadata);
    this.entityDataService.registerService('Relationship', relationshipsDataService)
    this.entityDataService.registerService('Post', postsDataService)
  }
}
