import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuard } from './auth/auth.guard';
import { SetupGuard } from './setup/setup.guard';
import { InviteTokenResolver } from './auth/invite-token-resolver';
import { AuthComponent } from './auth/auth.component';
import { AngularFireAuthGuard, customClaims, redirectUnauthorizedTo, redirectLoggedInTo, canActivate, hasCustomClaim } from '@angular/fire/auth-guard';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';


// const authInviteRequiredRoute = { path: 'auth/:inviteToken', resolve: { inviteToken: InviteTokenResolver},loadChildren: () => import('./auth/auth.module').then( m => m.AuthModule)};
// const authInviteNotRequiredRoute = { path: 'auth', loadChildren: () => import('./auth/auth.module').then( m => m.AuthModule)};

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['dashboard']);
// const redirectUnsubscribedToSubscription = () => pipe(hasCustomClaim('skipCreditCard'), map(isSubscribed => isSubscribed || ['settings','subscription']));
// const redirectUnsubscribedToSubscription = () => pipe(hasCustomClaim('stripeRole'), map(isSubscribed => isSubscribed || ['settings','subscription']));
const redirectUnsubscribedToSubscription = () => pipe(customClaims, map(claims => {
  return (!!claims?.stripeRole || claims?.onTrial == true) || ['settings','subscription']
}));

// const proOnly = () => pipe(customClaims, map(claims => claims.stripeRole === 'pro' || ['dashboard']));
const proOnly = () => pipe(customClaims, map(claims => claims.stripeRole === 'pro' || ['dashboard']));

const routes: Routes = [
  { path: 'auth', ...canActivate(redirectLoggedInToDashboard), component: AuthComponent },
  { path: 'auth/:inviteToken', ...canActivate(redirectLoggedInToDashboard), resolve: { inviteToken: InviteTokenResolver }, component: AuthComponent },
  { path: 'setup', ...canActivate(redirectUnauthorizedToLogin), canActivate: [SetupGuard], loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule) },
  {
    path: '',  ...canActivate(redirectUnauthorizedToLogin), component: HomeComponent, children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      // { path: 'feed', ...canActivate(redirectUnsubscribedToSubscription), ...canActivate(proOnly), loadChildren: () => import('./feed/feed.module').then(m => m.FeedModule) },
      // { path: 'post', ...canActivate(redirectUnsubscribedToSubscription), ...canActivate(proOnly), loadChildren: () => import('./post/post.module').then(m => m.PostModule) },
      { path: 'posts', ...canActivate(redirectUnsubscribedToSubscription), loadChildren: () => import('./feed/feed.module').then(m => m.FeedModule) },
      // { path: 'post', ...canActivate(redirectUnsubscribedToSubscription), loadChildren: () => import('./post/post.module').then(m => m.PostModule) },
      { path: 'dashboard', ...canActivate(redirectUnsubscribedToSubscription), loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'tribe', ...canActivate(redirectUnsubscribedToSubscription), loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule) },
      { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
    ]
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
