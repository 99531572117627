import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, map, tap, filter } from 'rxjs/operators';
import { AuthService } from '../auth/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SetupGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
    ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.loggedInUser.pipe(
      filter( u => !!u),
      take(1),
      map(user => {
        //TODO: what happens when user is null?

        // If the list of accounts which the user can access contains the currentIgUid and that
        // connection has been verified by the backend, no setup is required.
        const connectedAcc = user.canAccess?.find( acc => (acc.iguid === user.currentIgUid) && acc.verified)
        return !connectedAcc; // <-- map to boolean val which indicates if setup is required
      }), 
      tap(setupRequired => {
        // logger.info("Setup REQUIRED", setupRequired)
        if (!setupRequired) {
          return this.router.navigate(['/dashboard']);
        }
      })
    );
  }

}
