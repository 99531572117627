import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Post } from 'src/app/models/post';

/**
 * Entity service will allow us to interact with the store
 */

@Injectable({
  providedIn: "root"
})
export class PostEntityService extends EntityCollectionServiceBase<Post> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Post', serviceElementsFactory)
   }

}
