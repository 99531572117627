import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from './services/auth.service';
import {
  FormBuilder,
  FormGroup,
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { filter, tap } from 'rxjs/operators';
import { Invite } from './invite-token-resolver';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthComponent implements OnInit, OnDestroy {
	private __changeDetectorCounter__ = 0
  subscriptions: Subscription[] = []

  form: FormGroup;

  title = "Lets get you set up";
  description = "Connect the facebook account used to operate your business' facebook page";
  loading = false;

  inviteDetails: Invite;
  inviteRequired = environment.inviteRequired

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {

    this.inviteDetails = this.inviteRequired ? this.route.snapshot.data['inviteToken'] : undefined;
    this.subscriptions.push(
      this.authService.loggedInUser
        .pipe(filter(u => !!u))
        .subscribe(user => user.igAccount?.setupComplete ? this.router.navigate(['/dashboard']) : this.router.navigate(['/setup']))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async connectFacebook() {
    this.loading = true;
    // TODO: Use the credientials returned here to update the profile image
    // as the location of the profile image periodically changes locations + 
    // user may update their instagram display picture.
    await this.authService.connectFacebook();
    // this.analytics.logAnalyticsEvent(ApplicationEvent.FB_AUTH_BTN_CLICK)
  }
	__changeDectector__() {
		console.log("%c[ Auth component ] Change detection called", 'font-weight: bold;', this.__changeDetectorCounter__)
		this.__changeDetectorCounter__++
	}
}
