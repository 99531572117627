<main class="w-screen h-screen">
    <div class="text-center h-full flex flex-col justify-center">
      <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
        Not
        <br class="xl:hidden" />
        <span class="text-indigo-600">Found
        </span>
      </h2>
      <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
        The page you're looking for can not be found. <span routerLink="/" class="text-blue-400">Click here</span> to return to the home page.
      </p>
    </div>
  </main>