import { Pipe, PipeTransform } from '@angular/core';
import { LogService } from '../services/log.service';

interface TrialDate {
  seconds: number;
  nanoseconds: number;
}

@Pipe({
  name: 'trialLengthRemaining'
})
export class TrialLengthRemainingPipe implements PipeTransform {

  constructor(
    private logger: LogService
  ){}

  transform(trialEnd: TrialDate, ...args: any[]): any {

    if (!trialEnd){
      return null;
    }
    let period;
    let units;
    let delta

    try {
      delta = Math.abs(Date.now() - (this.secToMsec(trialEnd.seconds) + this.nsecToMsec(trialEnd.nanoseconds)))
    } catch (error) {
      console.log(error)
      this.logger.error("invalid trial end length", trialEnd)
      return null
    }



    // const diffTime = Math.abs(d2 - d1);
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    // const diffHours = Math.ceil(diffTime / (1000 * 60 * 60)); 
    // const diffMinu = Math.ceil(diffTime / (1000 * 60 * 60)); 

    if (this.inMinutes(delta) < 1){
      return "less than a minute";
    } else if (this.inMinutes(delta) < 60) {
      period = this.inMinutes(delta);
      units = "minutes";
    } else if (this.inHours(delta) < 24) {
      period = this.inHours(delta);
      units = "hours";
    } else {
      period = this.inDays(delta);
      units = "days";
    }

    // period = Math.ceil(period)
    console.log(period, units)

    return `${Math.ceil(period)} ${units}`
  }

  inDays(msec: number) {
    return msec / 8.64e+7;
  }

  inHours(msec: number) {
    return msec / 3.6e+6;
  }

  inMinutes(msec: number) {
    return msec / 60000;
  }

  secToMsec(sec){
    return sec * 1e3
  }

  nsecToMsec(nsec){
    return nsec / 1e6
  }
}
