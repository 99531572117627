

<container-element [ngSwitch]="getState()">
  <div *ngSwitchCase="'InProgress'" class="loader">
  </div>
  <div *ngSwitchCase="'Pass'">
    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle class="checkmark__circle pass" cx="26" cy="26" r="25" fill="none" />
      <path class="checkmark__check pass" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  </div>
  <div *ngSwitchCase="'Fail'">
    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle class="checkmark__circle fail" cx="26" cy="26" r="25" fill="none" />
      <path class="checkmark__check fail" fill="none" d="M16 16 36 36 M36 16 16 36" />
      </svg>  
  </div>
  <div *ngSwitchDefault>
  </div>
</container-element>

 