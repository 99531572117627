import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, DocumentSnapshotDoesNotExist, Action, DocumentSnapshotExists, DocumentReference, DocumentSnapshot } from '@angular/fire/firestore';
import { debounceTime, filter, map, mergeMap, shareReplay, switchMap, take, takeUntil, tap, toArray } from 'rxjs/operators';
import { combineLatest, forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { User, UserStatus } from '../models/user';
import { LogService } from './log.service';
import firebase from 'firebase/app';
import { Note } from '../feed/post/conversations/conversations.component';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ShortNumberPipe } from '../pipes/short-number.pipe';
import { Router } from '@angular/router';
import { Post } from '../models/post';
import { Relationship } from '../models/relationship';
import { Reply } from '../models/reply';
import { Comment } from '../models/comment';
import { PostDataService } from '../feed/services/post-data.service';
import { PostEntityService } from '../feed/services/post-entity.service';
import { GraphApiService } from './graph-api.service';



@Injectable({
  providedIn: 'root'
})
export class FirestoreService implements OnDestroy {

  subscriptions: Subscription[] = [];

  constructor(
    private afs: AngularFirestore,
    private aff: AngularFireFunctions,
    private logger: LogService,
    private graphApiService: GraphApiService,
    private router: Router,
    private shortNumberPipe: ShortNumberPipe
    ) { }

  /** Takes whats in the user auth state and updates the firestore users collections */
  registerUser(newUser) {
    // Set user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${newUser.uid}`);
    return userRef
      .snapshotChanges()
      .pipe(take(1))
      .pipe(switchMap((snap: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<User>>) => {

        if (!snap.payload.exists || !snap.payload.data().email) {
          return userRef.set(newUser, { merge: true })
        } else {
          return of({ message: 'user already registered' })
        }
      }))
  }

  async updateUserDetails(userId, userData) {
    // Set user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${userId}`);
    const doc = userRef
      .snapshotChanges()
      .pipe(take(1))
      .toPromise();

    // userRef.set(userData, { merge: true }) can also do updates itself but have seen evidence that this
    // is done in multiple steps and since we're watching for any changes to the users document, we would first
    // get the diff (i.e. the userData object) we just applied, then the whole document. This causes we're behaviour
    // as for a brief moment most of the user data within the app is missing a bunch of fields. Using update explicitly
    // seems to have fixed this issue.
    return await doc.then((snap: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<User>>) => snap.payload.exists ? userRef.update(userData) : userRef.set(userData, { merge: true }));
  }

  setupStatus(igUid: string) {

    return this.afs.collection('ig_accounts').doc(igUid).collection('setup').doc('status').valueChanges()
      .pipe(map((status: any) => {
        return { percentage: status?.loadedPercentage || 0, complete: status?.complete || false }
      }))
  }

  watchIgUidChanges(igUid: string) {
    return this.afs.collection('ig_accounts').doc(igUid).valueChanges()
  }

  igUidAccountDetails(igUid: string, username: string) {
    return this.watchAllStats(igUid, username).pipe(map(allStats => {
      let commentsCount = 0;
      let replyCount = 0;
      let tribeScore = 0;
      let commentersCount = 0;

      for (let i = 0; i < allStats.length; i++) {
        const statData = allStats[i]
        if (statData.username !== username) {
          commentsCount += statData.commentCount || 0
          commentersCount += 1
          replyCount += statData.replyCount || 0 // can't have negative replies
          tribeScore += (statData.commentCount >= 10 && statData.responseRate >= 30) ? 1 : 0;
        }
      }

      return {
        commentsCount,
        replyCount,
        tribeScore,
        commentersCount,
      }
    }))
      .pipe(tap(accDeets => this.setIgAccountDetails(igUid, { accountDetails: accDeets })))
      .pipe(shareReplay(1))
  }

  igUidSyncDetails(igUid: string) {
    return this.watchIgUidChanges(igUid).pipe(map((acc: any) => {
      return acc.syncInfo
    }))
  }

  tribeScore(igUid: string) {
    return this.watchIgUidChanges(igUid).pipe(map((acc: any) => acc.accountDetails.tribeScore))
  }

  getLinkedIgDetails(igUid) {
    const details = this.afs.collection('ig_accounts').doc(igUid).get()
      .pipe(map((doc: any) => {
        return doc.data().accountDetails
      }));
    const setupStatus = this.setupStatus(igUid).pipe(take(1))

    return forkJoin({ details, setupStatus })
  }

  setIgAccountDetails(igUid, object) {
    return this.afs.collection('ig_accounts').doc(igUid).set({ ...object }, { merge: true })
  }

  getIgDetails(igUid) {
    return this.afs.collection('ig_accounts').doc(igUid).get()
      .pipe(map((doc: any) => {
        return doc.data().accountDetails
      }));
  }

  getUserDetails(uid: string) {

    return this.afs.doc(`users/${uid}`).get();
  }

  getToken(uid: string) {

    return this.getUserDetails(uid)
      .pipe(map((doc: any) => {
        return doc.data().accessToken
      }));
  }

  onUserDetailsChange(id: string) {

    return this.afs.doc(`users/${id}`).valueChanges()
  }

  updateStats(igUid: string, username: string, object: any) {
    return this.afs.doc(`ig_accounts/${igUid}/statistics/@${username}`).set({ ...object }, { merge: true });
  }

  watchAllStats(igUid: string, username: string) {
    // Instagram accounts that have been deactivated will have commentCount = 0 so we filter those out
    return this.afs.collection('ig_accounts').doc(igUid).collection('statistics', ref => ref.where('commentCount', '>', 0).orderBy('commentCount', 'desc')).valueChanges()
      .pipe(shareReplay(1))
      .pipe(map(allStats => allStats.filter(stat => stat.username !== username)))
  };

  watchRelationshipChanges(igUid: string): Observable<Relationship[]> {
    return this.afs.collection('ig_accounts').doc(igUid)
      .collection('statistics', ref => ref.where('commentCount', '>', 0)).stateChanges()
      .pipe(shareReplay(1))
      .pipe(map((changes: any[]) => changes.map(change => change.payload.doc.data() as Relationship)))
  }

  watchPostChanges(igUid: string) {
    return this.afs.collection('ig_accounts').doc(igUid)
      .collection('media').stateChanges()
      .pipe(shareReplay(1))
      .pipe(map((changes: any[]) => changes.map(change => change.payload.doc.data() as Post)))
      .pipe(map((posts: Post[]) => posts.map(media => {
        return {
          ...media,
          responseRate: media.followerCommentCount > 0 ? (media.replyCount * 100.0) / media.followerCommentCount : 0
        }
      })))
  }

  watchAllMedia(igUid: string) {
    return this.afs.collection('ig_accounts').doc(igUid)
      .collection('media', ref => ref.orderBy('timestamp', 'desc')).valueChanges().pipe(shareReplay(1))
      .pipe(map((allMedia: any) => allMedia.map(media => {
        return {
          ...media,
          responseRate: media.followerCommentCount > 0 ? (media.replyCount * 100.0) / media.followerCommentCount : 0
        }
      })))
  }

  watchMediaChanges(igUid: string) {
    return this.afs.collection('ig_accounts').doc(igUid)
      .collection('media', ref => ref.orderBy('timestamp', 'desc')).stateChanges()
      .pipe(shareReplay(1))
      .pipe(map((allMedia: any) => allMedia.map(media => {
        return {
          ...media,
          responseRate: media.followerCommentCount > 0 ? (media.replyCount * 100.0) / media.followerCommentCount : 0
        }
      })))
  }

  updateMedia(igUid, postId, update){
    return this.afs.doc(`ig_accounts/${igUid}/media/${postId}`).set({ ...update }, { merge: true });
  }

  getAllPosts(igUid: string){
    return this.watchAllMedia(igUid)
      .pipe(take(1))
  }

  getAllRelationships(igUid: string){
    return this.afs.collection('ig_accounts').doc(igUid).collection('statistics', ref => ref.where('commentCount', '>', 0).orderBy('commentCount', 'desc')).valueChanges()
      .pipe(take(1))
  }

  getRelationships(igUid: string, username: string){
    return this.afs.collection('ig_accounts').doc(igUid).collection('statistics', ref => ref.where('username', '==', username)).valueChanges()
      .pipe(take(1))
      .pipe(map(relationships => relationships as Relationship[]))
  }

  getStatStateChanges(igUid: string) {
    return this.afs.collection('ig_accounts').doc(igUid).collection('statistics', ref => ref.where('commentCount', '>', 0))
      .stateChanges()
  }

  validateInviteToken(token) {
    return this.afs.collection('invitelinks').doc(token).get()
  }

  updateInviteToken(updatedInvite) {
    return this.afs.doc(`invitelinks/${updatedInvite.id}`).update(updatedInvite);  // merge to only update changes
  }

  requestInviteLink(details: any) {
    return this.afs.collection('inviteRequests').add(details);  // merge to only update changes
  }

  getMediaComments(igUid: string, mediaId: string) {
    return this.afs.collection('ig_accounts').doc(igUid)
      .collection('comments', ref => ref.where('mediaId', '==', mediaId)).valueChanges()
      // this occassionally fires twice. Use debounceTime to cover
      .pipe(debounceTime(1000))  
      .pipe(map((comments: any) => {
        return comments.sort((a, b) => (a.timestamp < b.timestamp) ? 1 : -1)
      }))
  }

  getLatestMedia(igUid: string) {
    return this.afs.collection('ig_accounts').doc(igUid)
      .collection('media', ref => ref.orderBy('timestamp', 'desc').limit(1)).get()
      .pipe(map((media: any) => {
        return media.docs[0].data()
      }))
  }

  getCommentReplies(igUid: string, commentId: string){
    return this.afs.collection('ig_accounts').doc(igUid)
      .collection('replies', ref => ref.where('commentId', '==', commentId).orderBy('timestamp', 'desc')).valueChanges()
      .pipe(map( replies => replies as Reply[]))
  }

  getConversation(igUid: string, username: string, higUsername){
    const comments$ = this.afs.collection('ig_accounts').doc(igUid)
    .collection('comments', ref => ref.where('username', '==', username)).valueChanges()
    .pipe(map( comments => comments as Comment[]))
    
    const higComments$ = this.afs.collection('ig_accounts').doc(igUid)
    .collection('comments', ref => ref.where('username', '==', higUsername).where('tagged', 'array-contains', username)).valueChanges()
    .pipe(map( comments => comments as Comment[]))

    const replies$ = this.afs.collection('ig_accounts').doc(igUid)
    .collection('replies', ref => ref.where('username', '==', username)).valueChanges()
    .pipe(map( replies => replies as Reply[]))
    
    const higReplies$ = this.afs.collection('ig_accounts').doc(igUid)
    .collection('replies', ref => ref.where('username', '==', higUsername).where('tagged', 'array-contains', username)).valueChanges()
    .pipe(map( replies => replies as Reply[]))

    return combineLatest([comments$, higComments$, replies$, higReplies$])
  }

  getNotes(igUid: string, username: string) {
    return this.afs.collection('ig_accounts').doc(igUid)
      .collection('notes', ref => ref.where('username', '==', username).orderBy('timestamp', 'desc'))
      .valueChanges({ idField: 'id' }) as Observable<Note[]>
  }

  addNote(igUid: string, note: Note) {

    return this.afs.collection(`ig_accounts/${igUid}/notes`).add({
      ...note,
      timestamp: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  updateNote(igUid: string, noteId: string, note: Note) {
    return this.afs.doc(`ig_accounts/${igUid}/notes/${noteId}`).update({
      ...note,
      // timestamp: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  deleteNote(igUid: string, noteId: string) {
    return this.afs.doc(`ig_accounts/${igUid}/notes/${noteId}`).delete()
  }

  deleteComment(igUid: string, comment: any) {
    const commentId = comment.id

    this.subscriptions.push(
      this.getCommentReplies(igUid, commentId)
        .pipe(mergeMap(replies => from(replies)
          .pipe(switchMap(reply => this.deleteReply(igUid, reply)))
        ))
        .subscribe()
    )
    // Delete comment and update all statistics
    return this.delete(igUid, comment, this.commentRef(igUid, commentId))
  }

  deleteReply(igUid: string, reply: any) {
    const replyId = reply.id

    return this.delete(igUid, reply, this.replyRef(igUid, replyId))
  }

  delete(igUid: string, comment: any, ref: DocumentReference) {
    const batch = this.afs.firestore.batch();
    const deletedCollectionRef = this.afs.doc(`ig_accounts/${igUid}/deleted/${comment.id}`).ref

    batch.delete(ref)
    batch.set(deletedCollectionRef, { ...comment, delete: true, deleteTime: (new Date()).toISOString() })
    return of(batch.commit())
  }

  commentRef(igUid: string, commentId: string) {
    return this.afs.doc(`ig_accounts/${igUid}/comments/${commentId}`).ref
  };

  replyRef(igUid: string, replyId: string) {
    return this.afs.doc(`ig_accounts/${igUid}/replies/${replyId}`).ref
  };

  toggleFavourite(updatedComment: any, igUid: string) {
    return this.afs.collection('ig_accounts').doc(igUid)
      .collection(updatedComment.commentId ? 'replies' : 'comments').doc(updatedComment.id).update({ favorite: updatedComment.favorite });
  }

  logUserAnalytics(uid, iguid, event) {
    return this.afs.doc(`analytics/${uid}/${iguid}/${event}`).set({ count: firebase.firestore.FieldValue.increment(1), latestEventOccurence: (new Date()).toISOString() }, { merge: true })
  }

  initStripeCheckoutSession(priceId: string, uid: string, rewardfulReference: string) {
    return this.afs
      .collection('users')
      .doc(uid)
      .collection('checkout_sessions')
      .add({
        price: priceId, // todo price Id from your products price in the Stripe Dashboard
        success_url: window.location.origin, // return user to this screen on successful purchase
        cancel_url: window.location.origin, // return user to this screen on failed purchase
        client_reference_id: rewardfulReference
      })
  }

  getHttpCallableFunction(functionName: string) {
    return this.aff.httpsCallable(functionName)
  }

  hasSubscription(uid: string) {
    return this.getUserDetails(uid)
      .pipe(map((doc: any) => {
        if(!doc.exists){ return false }
        const status = doc.data().userStatus
        return status === UserStatus.ACTIVE || status === UserStatus.TRIALING
      }));
  }

  // hasStripeSubscription(uid: string) {
  //   return this.afs.collection('users')
  //     .doc(uid)
  //     .collection('subscriptions', ref => ref.where('status', 'in', ['trialing', 'active']))
  //     .get()
  //     .pipe(map(sub => !sub.empty))
  // }

  getSubscription(uid: string) {
    return this.afs.collection('users')
      .doc(uid)
      .collection('subscriptions', ref => ref.where('status', 'in', ['trialing', 'active']))
      .valueChanges()
      .pipe(filter(snap => snap.length === 1))
      .pipe(map(subs => subs[0]))
      .pipe(switchMap(async (sub: any) => {
        const price$ = await sub.price.get()
        const product$ = await sub.product.get()

        return { price: price$.data(), product: product$.data() }
      }))
  }

  watchSubscription(uid: string) {
    return this.afs.collection('users')
      .doc(uid)
      .collection('subscriptions')
      .valueChanges()
  }

  getDocFromRef(ref) {
    return this.afs.doc(ref).get()
  }

  getSubscriptionPlans() {
    return this.afs.collection('products', ref => ref.where('active', '==', true))
      .snapshotChanges()
      .pipe(
        switchMap((products: any[]) => {
          const res = products.map((p: any) => {
            const data = p.payload.doc.data()
            // Add features
            if (data.stripe_metadata_features) {
              data['features'] = JSON.parse(data.stripe_metadata_features)
            }
            return this.getPrices(p.payload.doc.ref).pipe(map(prices => Object.assign(data, { prices })))
          })
          return combineLatest(res)
        })
      )
  }

  getPrices(ref) {
    return this.afs.doc(ref).collection('prices', ref => ref.where('active', '==', true)).snapshotChanges()
      .pipe(mergeMap(plans => from(plans).pipe(map(p => { return { id: p.payload.doc.id, ...p.payload.doc.data() } }),
        toArray()
      )))
  }

  notifyAdminOnSignUp(user: User) {
    const followers = this.shortNumberPipe.transform(user.igAccount.followersCount)
    const subject = `NEW SIGNUP | ${user.igAccount.username}: ${followers}`
    const htmlMessage = `
    <h3>** New Signup **</h3>
    <p>
      <b>Name:</b> ${user.displayName}<br>
      <b>Username:</b> ${user.igAccount.username}<br>
      <b>IGUID:</b> ${user.currentIgUid}<br>
      <b>Followers:</b> ${followers}<br>
      <b>Instagram:</b> https://instagram.com/${user.igAccount.username} <br>
    </p>
    `;

    this.afs.collection("mail").add({
      to: 'aboma@tribified.com',
      replyTo: 'aboma@tribified.com',
      message: {
        subject: subject,
        html: htmlMessage,
      },
    })
  }

  startTrial(uid) {
    const TRIAL_PERIOD_IN_MS = 14 * 24 * 60 * 60 * 1000; // 14 days
    const trialEnd = firebase.firestore.Timestamp.fromMillis(Date.now() + TRIAL_PERIOD_IN_MS)

    this.setOnTrialCustomClaim(uid, true)
      .then(() => this.setTrialEndTrigger(uid, trialEnd))
      .then(() => this.refreshToken())
      .then(() => this.updateUserDetails(uid, {
        userStatus: UserStatus.TRIALING,
        trialStart: firebase.firestore.FieldValue.serverTimestamp(),
        trialEnd
      }))
      .then(() => this.router.navigate(['/dashboard']))
  }

  setTrialEndTrigger(uid, trialEnd) {
    return this.afs
      .collection("queued_writes")
      .add({
        state: "PENDING",
        doc: `users/${uid}`,
        data: { userStatus: UserStatus.TRIAL_END },
        deliverTime: trialEnd
      })
      .then(() => {
        return this.afs
          .collection("queued_writes")
          .add({
            state: "PENDING",
            doc: `user_claims/${uid}`,
            data: { onTrial: false },
            deliverTime: trialEnd
          })
      })
  }

  // This gives access to routes by setting a onTrial custom claim
  setOnTrialCustomClaim(uid: string, trial: boolean) {
    return this.afs.collection("user_claims")
      .doc(uid)
      .set({
        onTrial: trial,
      });
  }

  refreshToken() {
    const currentUser = firebase.auth().currentUser;

    firebase
      .firestore()
      .collection("user_claims")
      .doc(currentUser.uid)
      .onSnapshot(async () => {
        // force a refresh of the ID token, which will pick up new claims
        const tokenResult = await currentUser.getIdTokenResult(true);
      });
  }

  cancelTrialEndTrigger(uid) {
    const scheduledUserStatusTrigger$ = this.afs.collection('queued_writes', ref => ref.where('doc', '==', `users/${uid}`)).valueChanges({ idField: 'id' }).pipe(take(1))
    const scheduledUserClaimTrigger$ = this.afs.collection('queued_writes', ref => ref.where('doc', '==', `user_claims/${uid}`)).valueChanges({ idField: 'id' }).pipe(take(1))

    scheduledUserStatusTrigger$
      .pipe(switchMap(docRef => !!docRef.length ? this.afs.collection('queued_writes').doc(docRef[0].id).delete() : of({})))
      .pipe(switchMap(_ => scheduledUserClaimTrigger$))
      .pipe(switchMap(docRef => !!docRef.length ? this.afs.collection('queued_writes').doc(docRef[0].id).delete() : of({})))
      .subscribe();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
