import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortNumber'
})
export class ShortNumberPipe implements PipeTransform {

  transform(num: number, ...args: any[]): any {

    if (isNaN(num) || num === null){
      return null;
    }

    let abs = Math.abs(num);

    // if less than 1000
    if (abs < Math.pow(10,3)){
      return num.toString();
    }

    const rounder = Math.pow(10,1);
    const isNegative = num < 0;

    let key = '';

    const powers = [
      {key: 'Q', value: Math.pow(10,15)},
      {key: 'T', value: Math.pow(10,12)},
      {key: 'B', value: Math.pow(10,9)},
      {key: 'M', value: Math.pow(10,6)},
      {key: 'K', value: Math.pow(10,3)},
    ];

    for (let i =0; i < powers.length; i++){
      let reduced = abs / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;

      if (reduced >= 1){
        abs = reduced;
        key = ' ' + powers[i].key;
        break;
      }
    }

    return (isNegative ? '-' : '') + abs + key;
  }

}
