import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Relationship } from 'src/app/models/relationship';


@Injectable({
  providedIn: 'root'
})
export class RelationshipEntityService extends EntityCollectionServiceBase<Relationship> {

  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('Relationship', serviceElementsFactory)
   }

}
