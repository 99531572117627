import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { FunctionsService } from '../services/functions.service';
import { ConfigurationService } from '../services/configuration.service';
import { Router } from '@angular/router';
import { filter, finalize, map } from 'rxjs/operators';
import { LogService } from '../services/log.service';
import { NotificationsService, Notification } from '../services/notifications.service';
import { BehaviorSubject, combineLatest, Observable, pipe, Subscription, UnaryFunction } from 'rxjs';
import { FirestoreService } from '../services/firestore.service';
import { LocalDatabaseService } from '../services/local-database.service';
import { DynamicCdnService } from '../services/dynamic-cdn.service';
import { StripeService } from '../services/stripe.service';
import firebase from 'firebase/app';
import { AngularFireAuthGuard, customClaims, redirectUnauthorizedTo, redirectLoggedInTo, canActivate, hasCustomClaim } from '@angular/fire/auth-guard';
import { MediaSyncService } from '../services/media-sync.service';
import { User, UserStatus } from '../models/user';
import { RelationshipEntityService } from '../charts/services/relationship-entity.service';
import { PostEntityService } from '../feed/services/post-entity.service';
import { ApplicationEvent } from '../models/event-types';
import { LoadingService } from '../services/loading.service';
import { LocalStorageService } from '../services/local-storage.service';


interface NavItem {
  id: string;
  name: string;
  route: string;
  gate?: string ;
}


@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('dropdown', [
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'scale(0.95)'
        }),
        animate('100ms ease-out')
      ]),
      transition(':leave', [
        animate('75ms ease-in', style({
          opacity: 0,
          transform: 'scale(0.95)'
        }))
      ])
    ]),
    trigger('notificationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('500ms ease-in-out', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('500ms ease-in-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('notificationTrigger1', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('500ms ease-in-out', style({  transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('trigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({  opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('200ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('trigger1', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(1rem)' }),
        animate('300ms ease-out', style({  opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('200ms ease-in', style({ opacity: 0, transform: 'translateY(1rem)' }))
      ])
    ])
  ]
})
export class ShellComponent implements OnInit, OnDestroy {

  menuOpen = false;
  mobileMenu = false;
  showNotificationsModal = false;
  showDemoModal = false;
  alwaysShowDemoVideo;

  syncInfo$: Observable<any>
  user$: Observable<User>
  igAccount$: Observable<User>

  mainNavItems: NavItem[] = [
    { id: 'dashboard', name: 'Dashboard', route: "dashboard" },
    { id: 'tribe', name: 'Tribe', route: "tribe" },
    { id: 'feed', name: 'Feed', route: "posts" },
    // { id: 'feed', name: 'Feed', route: "feed", gate: 'pro'},
    // { id: 'champions', name: 'Brand Champions' },
    // { id: 'upgrade', name: 'Upgrade' },
    // { id: 'reports', name: 'Reports' },
  ]

  subscriptions: Subscription[] = [];
  hasSubscription$;

  customClaims$: Observable<string>

  spinner$: Observable<boolean>
  loading$: Observable<boolean>
  enableMenus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  
  constructor(
    public authService: AuthService,
    private router: Router,
    private functionsService: FunctionsService,
    private firestoreService: FirestoreService,
    private configurationService: ConfigurationService,
    private notificationsService: NotificationsService,
    private loadingService: LoadingService,
    private changeDetectorRef: ChangeDetectorRef,
    private stripeService:StripeService,
    private localStorage: LocalStorageService
    ) { }

  ngOnInit(): void {
    // const user = this.localStorage.storageUserData
    this.user$ = this.authService.loggedInUser.pipe(filter(u => !!u))

    this.customClaims$ = this.stripeService.customClaim$;

    this.hasSubscription$ = this.stripeService.hasSubscription$

    this.loading$ = this.loadingService.loading$;

    // this.loading$ = combineLatest([this.relationshipsEntityService.loading$, this.postsEntityService.loading$])
    //                 .pipe(map( loaders => loaders[0] || loaders[1]))
    
    this.user$.subscribe( user => {
      const provideAccess = user.userStatus == UserStatus.TRIALING || user.userStatus == UserStatus.ACTIVE
      this.enableMenus$.next(provideAccess)
      if(user.userStatus == UserStatus.ACTIVE){
        this.firestoreService.cancelTrialEndTrigger(user.uid)
      }
      
      // only show modal if user trialing or has subscription
      if( provideAccess && this.router.url === '/dashboard'){
        this.showDemoModal = user.showDemoVideo ?? true
        this.alwaysShowDemoVideo = user.showDemoVideo ?? true
      }
      this.syncInfo$ = this.firestoreService.igUidSyncDetails(user.igAccount.id)
    })
    
    this.spinner$ = this.functionsService.refreshSpinner$
  }

  // get igAccount() {
  //   return this.localStorage.storageUserData?.igAccount
  // }

  get notificationsCount(){
    return this.notificationsService.notificationsCount$;
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen
  }

  toggleMobileMenu() {
    this.mobileMenu = !this.mobileMenu
  }

  toggleNotificationsModal(){
    this.showNotificationsModal = !this.showNotificationsModal
  }

  toggleDemoModal(){
    this.showDemoModal = !this.showDemoModal

    // onClose
    if(!this.showDemoModal){
      this.firestoreService.updateUserDetails(this.localStorage.storageUserData.uid, { 
        showDemoVideo: this.alwaysShowDemoVideo
       })
    }
  }

  signOut() {
    this.authService.signOut()
  }

  refresh() {
    const user = this.localStorage.storageUserData
    this.configurationService.logAnalyticsEvent(ApplicationEvent.MANUAL_SYNC_START)
    this.subscriptions.push(this.functionsService.requestDataSubset(5, user.uid, user.igAccount.id, user.igAccount.username)
    .pipe(finalize(() => {
      this.configurationService.logAnalyticsEvent(ApplicationEvent.MANUAL_SYNC_COMPLETE)
      // For some reason, updating the loading indicator here isn't being registered by the
      // view so calling detect changes explicitly so it updates.
      // TODO: look into Angular Zone to figure out why -- this solution may be fine
      this.changeDetectorRef.markForCheck();
    })).subscribe())
  }

  navigateTo(route: string){
    this.loadingService.setLoading(true)
    this.mobileMenu = false
    this.router.navigate([`/${route}`])
  }

  ngOnDestroy(){
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
}
