
export enum ApplicationEvent {
    // Table Events
    TABLE_SORT_USERNAME_DESC = 'table_sort_by_username_desc',
    TABLE_SORT_USERNAME_ASC = 'table_sort_by_username_asc',
    TABLE_SORT_COMMENTS_DESC = 'table_sort_by_comments_desc',
    TABLE_SORT_COMMENTS_ASC = 'table_sort_by_comments_asc',
    TABLE_SORT_REPLIES_DESC = 'table_sort_by_replies_desc',
    TABLE_SORT_REPLIES_ASC = 'table_sort_by_replies_asc',
    TABLE_SORT_RESPONSERATE_DESC = 'table_sort_by_response_rate_desc',
    TABLE_SORT_RESPONSERATE_ASC = 'table_sort_by_response_rate_asc',
    TABLE_SORT_TAGS_DESC = 'table_sort_by_tags_desc',
    TABLE_SORT_TAGS_ASC = 'table_sort_by_tags_asc',
    // Tribe Events
    TRIBE_ADD = 'tribe_member_add',
    TRIBE_REMOVE = 'tribe_member_remove',
    FOCUSON_ADD = 'focus_on_follower_add',
    FOCUSON_REMOVE = 'focus_on_follower_remove',

    // Feed
    SHOW_FEED_RESPONSE_RATE= 'response_rate_preview_show',
    // Post Events
    SHOW_POST_STATS = 'post_stats_show',
    SHOW_POST_MODAL = 'post_modal_show',

    REPLY_ADD = 'reply_posted',
    REPLY_DELETE = 'reply_delete',
    COMMENT_ADD = 'comment_posted',
    COMMENT_DELETE = 'comment_delete',
    FAVOURITE_FROM_COMMENTS = 'comment_favorited_from_comments',
    UNFAVOURITE_FROM_COMMENTS = 'comment_unfavorited_from_comments',
    // Conversation History Events
    SHOW_CONV_HISTORY= 'chat_history_show',
    SHOW_ALL_TAB = 'all_tab_show',
    SHOW_FAVOURITES_TAB = 'favorites_tab_show',
    SHOW_NOTES_TAB = 'notes_tab_show',
    // Note Events
    NOTE_UPDATE = 'note_update',
    NOTE_ADD = 'note_add',
    NOTE_DELETE = 'note_delete',

    SHOW_PREVIEW_POST = 'chat_post_preview',
    PREVIEW_POST_CLICK = 'chat_post_preview_click',

    GOTO_EXTERNAL_IG_PROFILE = 'goto_ig_profile',

    FAVOURITE_FROM_CHAT = 'comment_favorited_from_chat',
    UNFAVOURITE_FROM_CHAT = 'comment_unfavorited_from_chat',

    EMAIL_SIGNUP = 'email_signup',
    EMAIL_UPDATE = 'update_email',
    USER_STATUS_UPDATE = 'update_user_status',

    // Auto Sync Test Events
    AUTO_SYNC_START = 'auto_sync_test_start',
    AUTO_SYNC_COMPLETE = 'auto_sync_test_complete',
    AUTO_SYNC_FAIL = 'auto_sync_test_fail',
    AUTO_SYNC_CANCEL = 'auto_sync_test_cancel',

    // Setup
    SETUP_START = 'setup_start',
    SETUP_COMPLETE = 'setup_complete',

    MANUAL_SYNC_START = 'manual_sync_start',
    MANUAL_SYNC_COMPLETE = 'manual_sync_complete',
    FB_AUTH_BTN_CLICK = 'fb_auth_btn_click',
  }
