import { Injectable } from '@angular/core';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private _storageUserData = 'userData';

  constructor() { }

  get storageUserData() {
    return JSON.parse(localStorage.getItem(this._storageUserData));
  }

  set storageUserData(user: User ) {
    user ? localStorage.setItem(this._storageUserData, JSON.stringify(user)) : localStorage.removeItem(this._storageUserData)
  }
}
