import { Component } from '@angular/core';
import { AuthService } from '../auth/services/auth.service';
import { FunctionsService } from '../services/functions.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {

  isOpen: boolean = false;
  inviteRequired = environment.inviteRequired;

  constructor(
    public authService: AuthService
  ) { 
  }
}
